import React, {useContext, useState, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import {Table} from 'react-bootstrap';
import 'boxicons/css/boxicons.css';
import 'boxicons';
import {Breadcrumb} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import axios from 'axios';
import Pagination from 'react-bootstrap/Pagination';
import {Modal, Button} from 'react-bootstrap';
import ReactPaginate from "react-paginate";
import '../../style.css';
import successNotify from '../success-notify';
import errorNotify from '../error-notify';
import {Context} from '../../index';
import { API_URL } from '../../http';


const BooksPage = () => {
  const [items, setItems] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const {store} = useContext(Context);
  let limit = 5;

  const [showModalBooks, setShowModalBooks] = useState(false);

  const [deleteBooksId, setDeleteBooksId] = useState('');
  const [deleteBooksTitle, setDeleteBooksTitle] = useState('');

  const handleCloseModalBooks = () => setShowModalBooks(false);
  const handleShowModalBooks = (books_id, title) => {
    setDeleteBooksId(books_id);
    setDeleteBooksTitle(title);
    setShowModalBooks(true);
  }


  const getBooks = async () => {
    const res = await fetch(
      API_URL + `/b/all?page=1&limit=${limit}`, {
        headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
       });
    const data = await res.json();
    const total = res.headers.get("x-total-count");
    setpageCount(Math.ceil(total / limit));
    setItems(data);
  };

  useEffect(() => {
    getBooks();
  }, [limit]);

  const fetchBooks = async (currentPage) => {
    const res = await fetch(
      API_URL + `/b/all?page=${currentPage}&limit=${limit}`, {
        headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
       });
    const data = await res.json();
    return data;
  };

  const handlePageClick = async (data) => {

    let currentPage = data.selected + 1;

    const commentsFormServer = await fetchBooks(currentPage);

    setItems(commentsFormServer);
  };

  async function addLog(description) {
    const formData = new FormData()
    formData.append('username', store.user.email)
    formData.append('description', description)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/l/create', formData)
    .then(res => {
      console.log(res);
    })
    .catch(err => console.log(err));
  }

  async function removeBooks(books_id) {
    const formData = new FormData()
    formData.append('books_id', books_id)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/b/delete', formData)
    .then(res => {
      handleCloseModalBooks();
      successNotify(`Книга №` + books_id + ` была удалена!`);
      addLog(`Удалил книгу № ${books_id}`);
      getBooks();
    })
    .catch(err => console.log(err));
  }

  async function publishBooks(books_id) {
    const formData = new FormData()
    formData.append('books_id', books_id)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/b/publish', formData)
    .then(res => {
      successNotify(`Книга №` + books_id + ` была опубликована!`);
      addLog(`Опубликовал книгу № ${books_id}`);
      getBooks();
    })
    .catch(err => console.log(err));
  }

  async function unPublishBooks(books_id) {
    const formData = new FormData()
    formData.append('books_id', books_id)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/b/unpublish', formData)
    .then(res => {
      successNotify(`Книга №` + books_id + ` снята с публикации!`);
      addLog(`Снял с публикации книгу № ${books_id}`);
      getBooks();
    })
    .catch(err => console.log(err));
  }


    return (
      <div className="pt-2">
        <Modal
          show={showModalBooks}
          onHide={handleCloseModalBooks}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Удалить книгу?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Вы действительно хотите удалить книгу <strong>{deleteBooksTitle}</strong>? После подтверждения, данное действие отменить будет невозможно.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" className="outline-btn-bl" onClick={handleCloseModalBooks}>
              <i className='bx bx-layer-minus bx-fw' ></i> Отмена
            </Button>
            <Button variant="primary" className="btn-bl" onClick={() => removeBooks(deleteBooksId)}>
              <i className='bx bx-check bx-fw'></i> Удалить
            </Button>
          </Modal.Footer>
        </Modal>
        <Breadcrumb>
          <Link to="/" className="breadcrumb-item">
            Главная
          </Link>
          <Breadcrumb.Item active>Новости</Breadcrumb.Item>
        </Breadcrumb>
        <div className="shadow-sm p-4 bg-white rounded">
          <h5 className="pb-2">Новости</h5>
          <Link to="/books/add"><Button className="mb-2 btn-bl"><i className='bx bx-fw bxs-file-plus' ></i> Создать книгу</Button></Link>
          <table className="admintable mb-2">
          <thead>
            <tr>
              <th>№</th>
              <th>Название</th>
              <th>Стоимость</th>
              <th>Статус публикации</th>
              <th>Действия</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item) => {
              return (
                <tr key={item.books_id}>
                <td data-label="№">{item.books_id}</td>
                <td data-label="Название">{item.title}</td>
                <td data-label="Стоимость">{item.cost} ₽</td>
                <td data-label="Статус публикации">{item.published ? "Опубликовано" : "Не опубликовано"}</td>
                <td>
                  <div className={"inline"}>
                    <Link className="ico-btn ico-edit" to={`/books/edit/${item.books_id}`}><i className='bx bx-edit bx-fw' ></i></Link>
                    <a className="ico-btn ico-visible" onClick={() => item.published? unPublishBooks(item.books_id) : publishBooks(item.books_id)}>
                      <i className={item.published ? "bx bx-low-vision bx-fw" : "bx bxs-low-vision bx-fw"}>
                      </i>
                    </a>
                    <a className="ico-btn ico-trash" onClick={() => handleShowModalBooks(item.books_id, item.title)}><i className='bx bx-trash bx-fw' ></i></a>
                  </div>
                </td>
                </tr>
              )
            })}
            </tbody>
          </table> 
          <ReactPaginate
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              previousLabel={"Назад"}
              nextLabel={"Вперед"}
              pageCount={pageCount}
              onPageChange={handlePageClick}
              containerClassName="pagination"
              breakClassName="page-item"
              breakLabel={<a className="page-link">...</a>}
              pageClassName="page-item"
              previousClassName="page-item"
              nextClassName="page-item"
              pageLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              activeClassName="active paginate-btn-active"
          />
        </div>
      </div>
    );
};

export default BooksPage;