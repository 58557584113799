import React, {useContext, useState, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import {Breadcrumb, Modal} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import 'boxicons/css/boxicons.css';
import 'boxicons';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import axios from 'axios';
import errorNotify from '../error-notify';
import successNotify from '../success-notify';
import { API_URL } from '../../http';
import {Context} from '../../index';

const CountryPage = () => {
  const {store} = useContext(Context);
  const [nameCountry, setNameCountry] = useState('');
  const [country, setCountry] = useState([]);
  const [city, setCity] = useState([]);
  const [currentCountry, setCurrentCountry] = useState('');
  const [activeKey, setActiveKey] = useState('');
  const [validated, setValidated] = useState(false);
  const [selectCountry, setSelectCountry] = useState('');

  const [selectName, setSelectName] = useState('');

  const [deleteCityId, setDeleteCityId] = useState('');
  const [deleteCityAddress, setDeleteCityAddress] = useState('');

  const [deleteCountryId, setDeleteCountryId] = useState('');
  const [deleteCountryAddress, setDeleteCountryAddress] = useState('');

  const [showModalCountry, setShowModalCountry] = useState(false);
  const [showModalCity, setShowModalCity] = useState(false);

  const handleCloseModalCountry = () => setShowModalCountry(false);
  const handleShowModalCountry = (id, name) => {
    setDeleteCountryId(id);
    setDeleteCountryAddress(name);
    setShowModalCountry(true);
  }

  const handleCloseModalCity = () => setShowModalCity(false);

  const handleShowModalCity = (id, address) => {
    setDeleteCityId(id);
    setDeleteCityAddress(address);
    setShowModalCity(true);
  }

  const getCountry = async () => {
    const res = await fetch(
      API_URL + `/c`
    );
    const data = await res.json();
    setCountry(data);
  };

  const getCity = async () => {
    const res = await fetch(
      API_URL + `/c/city`
    );
    const data = await res.json();
    setCity(data);
  };

  const getDefaultCountry = async () => {
    const res = await fetch(
      API_URL + `/c/default`
    );
    const data = await res.json();
    setCurrentCountry(data._id);
    setActiveKey(`#` + data._id);
    setSelectCountry(data._id);
  };

  useEffect(() => {
    getCountry();
  }, [setCountry]);

  useEffect(() => {
    getCity();
  }, [setCity]);

  useEffect(() => {
    getDefaultCountry();
  }, [setCurrentCountry]);

  const addCountry = () => {
    if (nameCountry == '') {
      return errorNotify('Заполните поле названия города!');
    }
    const formData = new FormData()
    formData.append('name', nameCountry)
    formData.append('published', "false")
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/c/create', formData)
    .then(res => {
       successNotify(`Страна ${nameCountry} добавлена!`);
       addLog(`Добавил страну ${nameCountry}`);
       getCountry();
       getCity();
    })
    .catch(err => {
      errorNotify(err.response.data.message);
    });
}

  const addCity = () => {
    if (selectName == '') {
      return errorNotify('Заполните наименование города!')
    }
    const formData = new FormData()
    formData.append('cid', selectCountry)
    formData.append('name', selectName)
    formData.append('published', "false")
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/c/city/create', formData)
    .then(res => {
      successNotify(`Город ${selectName} добавлен!`);
      addLog(`Добавил город ${selectName}`);
      getCity();
    })
    .catch(err => {
      errorNotify(err.response.data.message);
    });
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    addCity();
  };

  async function removeCity(_id) {
    const formData = new FormData()
    formData.append('_id', _id)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/c/city/delete', formData)
    .then(res => {
      successNotify(`Город ${deleteCityAddress} удален!`);
      handleCloseModalCity();
      addLog(`Удалил город ${deleteCityAddress}`);
      getCity();
    })
    .catch(err => {
      handleCloseModalCountry();
      errorNotify(err.response.data.message);
    });
  }

  async function addLog(description) {
    const formData = new FormData()
    formData.append('username', store.user.email)
    formData.append('description', description)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/l/create', formData)
    .then(res => {
      console.log(res);
    })
    .catch(err => console.log(err));
  }

  async function removeCountry(_id) {
    const formData = new FormData()
    formData.append('_id', _id)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/c/delete', formData)
    .then(res => {
      successNotify(`Страна ${deleteCountryAddress} удалена!`);
      addLog(`Удалил страну ${deleteCountryAddress}`);
      handleCloseModalCountry();
      getCountry();
    })
    .catch(err => {
      handleCloseModalCountry();
      errorNotify(err.response.data.message);
    });
  }

    return (
      <div className="pt-2">
      <Modal
        show={showModalCountry}
        onHide={handleCloseModalCountry}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Удалить страну?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Вы действительно хотите удалить страну <strong>{deleteCountryAddress}</strong>? После подтверждения, данное действие отменить будет невозможно.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" className="outline-btn-bl" onClick={handleCloseModalCountry}>
            <i className='bx bx-layer-minus bx-fw' ></i> Отмена
          </Button>
          <Button variant="primary" className="btn-bl" onClick={() => removeCountry(deleteCountryId)}>
            <i className='bx bx-check bx-fw'></i> Удалить
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showModalCity}
        onHide={handleCloseModalCity}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Удалить город?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Вы действительно хотите удалить город <strong>{deleteCityAddress}</strong>? После подтверждения, данное действие отменить будет невозможно.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" className="outline-btn-bl" onClick={handleCloseModalCity}>
            <i className='bx bx-layer-minus bx-fw' ></i> Отмена
          </Button>
          <Button variant="primary" className="btn-bl" onClick={() => removeCity(deleteCityId)}>
            <i className='bx bx-check bx-fw'></i> Удалить
          </Button>
        </Modal.Footer>
      </Modal>
        <Breadcrumb>
          <Link to="/" className="breadcrumb-item">
            Главная
          </Link>
          <Breadcrumb.Item active>Страны</Breadcrumb.Item>
        </Breadcrumb>
        <div className="shadow-sm p-4 bg-white rounded">
          <h5 className="pb-2">Страны и города</h5>
          <Tab.Container id="list-group-tabs-example" activeKey={activeKey} onSelect={eventKey => setActiveKey(eventKey)}>
            <Row>
              <Col sm={4}>
                <ListGroup className="bl-list-group">
                  {country.map((item) => {
                    return (
                      <ListGroup.Item key={item._id} action href={`#` + item._id}>
                      <i className='bx bx-buildings bx-fw' ></i> {item.name}
                        <div className="position-absolute d-inline end-0 px-2"><i onClick={() => handleShowModalCountry(item._id, item.name)} className='bx bx-trash bx-fw' ></i></div>
                      </ListGroup.Item>
                    )
                  })}
                  <InputGroup className="mt-2">
                    <Form.Control
                      value={nameCountry}
                      onChange={e => setNameCountry(e.target.value)}
                      placeholder="Добавить страну"
                      aria-label="Добавить страну"
                      aria-describedby="basic-addon2"
                    />
                    <Button onClick={() => addCountry()} variant="outline-primary" className="outline-btn-bl" id="button-addon2">
                      <i className='bx bx-fw bx-layer-plus' ></i> Добавить страну
                    </Button>
                  </InputGroup>
                </ListGroup>
              </Col>
              <Col sm={8}>
                <Tab.Content>
                  {country.map((country) => {
                    return (
                      <Tab.Pane key={country._id} eventKey={`#` + country._id}>
                        <table className="admintable mt-2 mt-md-0">
                          <thead>
                            <tr>
                              <th>Наименование города</th>
                              <th>Действия</th>
                            </tr>
                          </thead>
                          <tbody>
                            {city.map((city) => {
                              if (country._id === city.cid) {
                              return (
                                <tr key={city._id}>
                                  <td data-label="Наименование города">{city.name}</td>
                                  <td>
                                    <div className={"inline"}>
                                      <a className="ico-btn ico-trash"><i onClick={() => handleShowModalCity(city._id, city.name)} className='bx bx-trash bx-fw' ></i></a>
                                    </div>
                                  </td>
                                </tr>
                              )
                              }
                            })}
                          </tbody>
                        </table>
                      </Tab.Pane>
                    )
                  })}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
        <div className="shadow-sm p-4 bg-white rounded mt-2">
          <h5 className="pb-2">Добавить город</h5>
          <Form noValidate validated={validated}>
            <Row className="mb-3">
              <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label>Страна</Form.Label>
                  <Form.Select defaultValue={selectCountry} onChange={e => setSelectCountry(e.target.value)} aria-label="Страны">
                    {country.map((country) => {
                      return (
                        <option key={country._id} value={country._id}>{country.name}</option>
                      )
                    })}
                  </Form.Select>
                <Form.Control.Feedback>Страна выбрана</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="validationCustom03">
                <Form.Label>Название города</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Город"
                  required
                  value={selectName}
                  onChange={e => setSelectName(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Введите номер телефона
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Button className="btn-bl" onClick={handleSubmit}><i className='bx bx-fw bx-layer-plus' ></i> Добавить город</Button>
          </Form>
        </div>
      </div>
    );
};

export default CountryPage;