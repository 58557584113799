import React, { useEffect, useState, useContext } from 'react';
import {observer} from "mobx-react-lite";
import {Breadcrumb, InputGroup, Form, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import 'boxicons/css/boxicons.css';
import 'boxicons';
import { API_URL } from '../../http';
import successNotify from '../success-notify';
import axios from 'axios';
import {Context} from '../../index';



const SettingsPage = () => {

  const [items, setItems] = useState([]);
  const {store} = useContext(Context);

  const[s_title, setS_title] = useState('');
  const[s_desc, setS_desc] = useState('');
  const[s_card_1, setS_card_1] = useState('');
  const[s_card_2, setS_card_2] = useState('');
  const[s_card_3, setS_card_3] = useState('');
  const[s_card_4, setS_card_4] = useState('');
  const[l_telegram, setL_telegram] = useState('');
  const[l_whatsapp, setL_whatsapp] = useState('');
  const[l_zen, setL_zen] = useState('');
  const[l_privacy, setL_privacy] = useState('');
  const[op_link_yt_1, setOp_link_yt_1] = useState('');
  const[op_link_yt_2, setOp_link_yt_2] = useState('');
  const[op_link_yt_3, setOp_link_yt_3] = useState('');
  const[op_info_link_1, setOp_info_link_1] = useState('');
  const[op_info_link_2, setOp_info_link_2] = useState('');
  const[op_info_link_3, setOp_info_link_3] = useState('');
  const[op_info_link_4, setOp_info_link_4] = useState('');
  const[op_info_link_5, setOp_info_link_5] = useState('');

  const editSettings = () => {
    const formData = new FormData()
    formData.append('s_title', s_title)
    formData.append('s_desc', s_desc)
    formData.append('s_card_1', s_card_1)
    formData.append('s_card_2', s_card_2)
    formData.append('s_card_3', s_card_3)
    formData.append('s_card_4', s_card_4)
    formData.append('l_telegram', l_telegram)
    formData.append('l_whatsapp', l_whatsapp)
    formData.append('l_zen', l_zen)
    formData.append('l_privacy', l_privacy)
    formData.append('op_link_yt_1', op_link_yt_1)
    formData.append('op_link_yt_2', op_link_yt_2)
    formData.append('op_link_yt_3', op_link_yt_3)
    formData.append('op_info_link_1', op_info_link_1)
    formData.append('op_info_link_2', op_info_link_2)
    formData.append('op_info_link_3', op_info_link_3)
    formData.append('op_info_link_4', op_info_link_4)
    formData.append('op_info_link_5', op_info_link_5)

    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/s/edit', formData)
    .then(res => {
        addLog(`Обновил информацию в настройках`);
        successNotify(`Настройки были обновлены!`);
    })
    .catch(err => console.log(err));

}

async function addLog(description) {
  const formData = new FormData()
  formData.append('username', store.user.email)
  formData.append('description', description)
  axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
  axios.post(API_URL + '/l/create', formData)
  .then(res => {
  })
  .catch(err => console.log(err));
}

  const getSettings = async () => {
    const res = await fetch(
      API_URL + `/s`, {
        headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
       });
    const data = await res.json();
    if(data != null) {
      setS_title(data.s_title);
      setS_desc(data.s_desc);
      setS_card_1(data.s_card_1);
      setS_card_2(data.s_card_2);
      setS_card_3(data.s_card_3);
      setS_card_4(data.s_card_4);
      setL_telegram(data.l_telegram);
      setL_whatsapp(data.l_whatsapp);
      setL_zen(data.l_zen)
      setL_privacy(data.l_privacy);
      setOp_link_yt_1(data.op_link_yt_1);
      setOp_link_yt_2(data.op_link_yt_2);
      setOp_link_yt_3(data.op_link_yt_3);
      setOp_info_link_1(data.op_info_link_1);
      setOp_info_link_2(data.op_info_link_2);
      setOp_info_link_3(data.op_info_link_3);
      setOp_info_link_4(data.op_info_link_4);
      setOp_info_link_5(data.op_info_link_5);
    }
    setItems(data);
  };

  useEffect(() => {
    getSettings();
  }, [setItems]);

    return (
      <div className="pt-2">
      <Breadcrumb>
        <Link className="breadcrumb-item" to="/">
          Главная
        </Link>
        <Breadcrumb.Item active>Настройки</Breadcrumb.Item>
      </Breadcrumb>
        <div className="shadow-sm p-4 bg-white rounded">
          <h5 className="pb-2">Настройки</h5>
          
          <h6 className="pb-2">Основной сайт</h6>
          <InputGroup className="mb-3">
            <InputGroup.Text id="icon_1">Заголовок</InputGroup.Text>
            <Form.Control
              placeholder="Заголовок"
              aria-label="Заголовок"
              aria-describedby="icon_1"
              value={s_title}
              onChange={e => setS_title(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="title_1">Описание</InputGroup.Text>
            <Form.Control
              placeholder="Описание"
              aria-label="Описание"
              aria-describedby="title_1"
              value={s_desc}
              onChange={e => setS_desc(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="desc_1">Карточка №1</InputGroup.Text>
            <Form.Control
              placeholder="Введите описание..."
              aria-label="Введите описание..."
              aria-describedby="desc_1"
              value={s_card_1}
              onChange={e => setS_card_1(e.target.value)}
            />
          </InputGroup>

          <InputGroup className="mb-3 mt-5">
            <InputGroup.Text id="icon_2">Карточка №2</InputGroup.Text>
            <Form.Control
              placeholder="Введите описание..."
              aria-label="Введите описание..."
              aria-describedby="icon_2"
              value={s_card_2}
              onChange={e => setS_card_2(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="title_2">Карточка №3</InputGroup.Text>
            <Form.Control
              placeholder="Введите описание..."
              aria-label="Введите описание..."
              aria-describedby="title_2"
              value={s_card_3}
              onChange={e => setS_card_3(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="desc_2">Карточка №4</InputGroup.Text>
            <Form.Control
              placeholder="Введите описание..."
              aria-label="Введите описание..."
              aria-describedby="desc_2"
              value={s_card_4}
              onChange={e => setS_card_4(e.target.value)}
            />
          </InputGroup>

          <InputGroup className="mb-3 mt-5">
            <InputGroup.Text id="icon_3">Telegram</InputGroup.Text>
            <Form.Control
              placeholder="https://t.me/"
              aria-label="https://t.me/"
              aria-describedby="icon_3"
              value={l_telegram}
              onChange={e => setL_telegram(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="title_3">What's App</InputGroup.Text>
            <Form.Control
              placeholder="https://wa.me/"
              aria-label="https://wa.me/"
              aria-describedby="title_3"
              value={l_whatsapp}
              onChange={e => setL_whatsapp(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="desc_3">Yandex Zen</InputGroup.Text>
            <Form.Control
              placeholder="https://ya.ru/"
              aria-label="https://ya.ru/"
              aria-describedby="desc_3"
              value={l_zen}
              onChange={e => setL_zen(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="privacy">Политика конфиденциальности</InputGroup.Text>
            <Form.Control
              placeholder="Введите ссылку..."
              aria-label="Введите ссылку..."
              aria-describedby="privacy"
              value={l_privacy}
              onChange={e => setL_privacy(e.target.value)}
            />
          </InputGroup>
          <h6 className="pb-2 mt-5">Лендинги</h6>


          <InputGroup className="mb-3">
            <InputGroup.Text id="org">YouTube (Ленд 1)</InputGroup.Text>
            <Form.Control
              placeholder="Введите ссылку..."
              aria-label="Введите ссылку..."
              aria-describedby="org"
              value={op_link_yt_1}
              onChange={e => setOp_link_yt_1(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="inn">YouTube (Ленд 2)</InputGroup.Text>
            <Form.Control
              placeholder="Введите ссылку..."
              aria-label="Введите ссылку..."
              aria-describedby="inn"
              value={op_link_yt_2}
              onChange={e => setOp_link_yt_2(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="kpp">YouTube (Ленд 3)</InputGroup.Text>
            <Form.Control
              placeholder="Введите ссылку..."
              aria-label="Введите ссылку..."
              aria-describedby="kpp"
              value={op_link_yt_3}
              onChange={e => setOp_link_yt_3(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="ogrn">Условия (Ленд 1)</InputGroup.Text>
            <Form.Control
              placeholder="Введите ссылку..."
              aria-label="Введите ссылку..."
              aria-describedby="ogrn"
              value={op_info_link_1}
              onChange={e => setOp_info_link_1(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="address">Условия (Ленд 2)</InputGroup.Text>
            <Form.Control
              placeholder="Введите ссылку..."
              aria-label="Введите ссылку..."
              aria-describedby="address"
              value={op_info_link_2}
              onChange={e => setOp_info_link_2(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="director">Условия (Ленд 3)</InputGroup.Text>
            <Form.Control
              placeholder="Введите ссылку..."
              aria-label="Введите ссылку..."
              aria-describedby="director"
              value={op_info_link_3}
              onChange={e => setOp_info_link_3(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="phone">Условия (Ленд 4)</InputGroup.Text>
            <Form.Control
              placeholder="Введите ссылку..."
              aria-label="Введите ссылку..."
              aria-describedby="phone"
              value={op_info_link_4}
              onChange={e => setOp_info_link_4(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="email">Условия (Ленд 5)</InputGroup.Text>
            <Form.Control
              placeholder="Введите ссылку..."
              aria-label="Введите ссылку..."
              aria-describedby="email"
              value={op_info_link_5}
              onChange={e => setOp_info_link_5(e.target.value)}
            />
          </InputGroup>
          <Button onClick={() => editSettings()} className="btn-bl"><i className='bx bx-fw bx-check bx-flashing' ></i> Обновить данные</Button>






        </div>
      </div>
    );
};

export default observer(SettingsPage);