import React, {useContext, useState, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import {Table} from 'react-bootstrap';
import 'boxicons/css/boxicons.css';
import 'boxicons';
import {Breadcrumb, Form, Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import axios from 'axios';
import Pagination from 'react-bootstrap/Pagination';
import {Modal, Button} from 'react-bootstrap';
import ReactPaginate from "react-paginate";
import '../../style.css';
import successNotify from '../success-notify';
import errorNotify from '../error-notify';
import {Context} from '../../index';
import { API_URL } from '../../http';
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from 'date-fns/locale/ru';
registerLocale('ru', ru)


const MailingPage = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [items, setItems] = useState([]);
  const [arhiveItems, setArhiveItems] = useState([]);
  const {store} = useContext(Context);
  const [showModalMailing, setShowModalMailing] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [file, setFile] = useState(null);
  const [mailingTitle, setMailingTitle] = useState('');
  const [auditory, setAuditory] = useState('0');
  const [type, setType] = useState('0');
  const [selectTime, setSelectTime] = useState('');

  const [selectHours, setSelectHours] = useState('00');
  const [selectMinutes, setSelectMinutes] = useState('00');

  const selectFile = e => {
    setFile(e.target.files[0])
    if (e.target.files[0] == null){
        setMailingTitle(null);
    }
    else setMailingTitle(e.target.files[0].name);
  }

  const [showModalDelete, setShowModalDelete] = useState(false);

  const [deleteId, setDeleteId] = useState('');
  const [deleteTitle, setDeleteTitle] = useState('');

  const handleCloseModalDelete = () => setShowModalDelete(false);
  const handleShowModalDelete = (_id, title) => {
    setDeleteId(_id);
    setDeleteTitle(title);
    setShowModalDelete(true);
  }

  const handleCloseModalMailing = () => setShowModalMailing(false);
  const handleShowModalMailing = () => {
    setShowModalMailing(true);
  }


  const getMailing = async () => {
    const res = await fetch(
      API_URL + `/mailing/`, {
        headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
       });
    const data = await res.json();
    setItems(data);
  };

  const getArhiveMailing = async () => {
    const res = await fetch(
      API_URL + `/mailing/arhive`, {
        headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
       });
    const data = await res.json();
    setArhiveItems(data);
  };

  useEffect(() => {
    const now = new Date();
    const cd = now.getFullYear() + '-' + ("0"+(now.getMonth()+1)).slice(-2)+'-'+ ("0" + now.getDate()).slice(-2);
    setSelectTime(cd);
    getMailing();
    getArhiveMailing();
  }, [selectTime]);

  //title, content, img, datePost, type, auditory
  async function addMailing() {
    if (title == '' || content == '') {
        return errorNotify(`Необходимо заполнить все поля!`);
    }
    const formData = new FormData()
    formData.append('title', title)
    formData.append('content', content)
    formData.append('type', type)
    formData.append('img', file)
    formData.append('auditory', auditory)
    formData.append('dateIn', selectTime + 'T' + selectHours + ':' + selectMinutes)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/mailing/add', formData)
    .then(res => {
      successNotify(`Рассылка ` + title + ` была добавлена!`);
      addLog(`Добавил рассылку ${title}`);
      setTitle('');
      setContent('');
      setType('0');
      setAuditory('0');
      getMailing();
      getArhiveMailing();
      handleCloseModalMailing();
    })
    .catch(err => console.log(err));
  }


  async function handledata(date) {
    let selectedFormat = date.getFullYear() + '-' + ("0"+(date.getMonth()+1)).slice(-2)+'-'+ ("0" + date.getDate()).slice(-2);
    setSelectTime(selectedFormat);
    setStartDate(date);
  }


  async function addLog(description) {
    const formData = new FormData()
    formData.append('username', store.user.email)
    formData.append('description', description)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/l/create', formData)
    .then(res => {
      console.log(res);
    })
    .catch(err => console.log(err));
  }

  async function removeMailing(_id) {
    const formData = new FormData()
    formData.append('_id', _id)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/mailing/delete', formData)
    .then(res => {
      handleCloseModalDelete();
      successNotify(`Рассылка ` + deleteTitle + ` была удалена!`);
      addLog(`Удалил рассылку ${deleteTitle}`);
      getMailing();
      getArhiveMailing();
    })
    .catch(err => console.log(err));
  }

  async function selecttest(evalue) {
    console.log(evalue)
    setSelectHours(evalue)
  }


    return (
      <div className="pt-2">
        <Modal
          show={showModalMailing}
          onHide={handleCloseModalMailing}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Добавление рассылки</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Form.Group controlId="form-time-date" as={Col} md="12" className="mb-3">
                <Form.Label>Наименование</Form.Label>
                <Form.Control
                    placeholder="Введите название рассылки"
                    aria-label="Введите название рассылки"
                    aria-describedby="basic-addon2"
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    className="mb-2"
                />
            </Form.Group>
            <Row className="mb-3">
                <Form.Group controlId="form-time-hours" as={Col} md="4">
                    <Form.Label>Тип рассылки</Form.Label>
                    <Form.Select
                        aria-label="Default select example"
                        defaultValue={type}
                        onChange={e => setType(e.target.value)}
                    >
                        <option value="0">Новости</option>
                        <option value="1">Продукты</option>
                        <option value="2">Системные</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group controlId="form-time-mins" as={Col} md="8">
                    <Form.Label>Аудитория</Form.Label>
                    <Form.Select 
                        aria-label="Default select example"
                        defaultValue={auditory}
                        onChange={e => setAuditory(e.target.value)}
                    >
                        <option value="0">Только пользователи</option>
                        <option value="1">Только практики</option>
                        <option value="2">Только мастера</option>
                        <option value="3">Все пользователи</option>
                    </Form.Select>
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Form.Group controlId="form-time-date" as={Col} md="4">
                <Form.Label>Дата</Form.Label>
                    <DatePicker className="form-control" dateFormat="dd.MM.yyyy" locale="ru" selected={startDate} onChange={(date) => handledata(date)} />
                </Form.Group>
                <Form.Group controlId="form-time-hours" as={Col} md="4">
                    <Form.Label>Часы</Form.Label>
                    <Form.Select
                        aria-label="Default select example"
                        defaultValue={selectHours}
                        onChange={e => selecttest(e.target.value)}
                    >
                        <option value="00">00</option>
                        <option value="01">01</option>
                        <option value="02">02</option>
                        <option value="03">03</option>
                        <option value="04">04</option>
                        <option value="05">05</option>
                        <option value="06">06</option>
                        <option value="07">07</option>
                        <option value="08">08</option>
                        <option value="09">09</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group controlId="form-time-mins" as={Col} md="4">
                    <Form.Label>Минуты</Form.Label>
                    <Form.Select 
                        aria-label="Default select example"
                        defaultValue={selectMinutes}
                        onChange={e => setSelectMinutes(e.target.value)}
                    >
                        <option value="00">00</option>
                        <option value="05">05</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                        <option value="25">25</option>
                        <option value="30">30</option>
                        <option value="35">35</option>
                        <option value="40">40</option>
                        <option value="45">45</option>
                        <option value="50">50</option>
                        <option value="55">55</option>
                    </Form.Select>
                </Form.Group>
            </Row>
            <Form.Group as={Col} md="12" controlId="validationCustom04">
            <Form.Label>Содержимое</Form.Label>
            <Form.Control
                required
                placeholder="Введите содержимое рассылки"
                aria-label="Введите содержимое рассылки"
                as="textarea"
                style={{ height: '200px' }}
                value={content}
                onChange={e => setContent(e.target.value)}
            />
            </Form.Group>
            <Form.Group as={Col} md="12" controlId="validationCustom04" className="mt-3">
              <Form.Label>Изображение</Form.Label>
              <Form.Control 
                required
                type="file"
                onChange={selectFile}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" className="outline-btn-bl" onClick={handleCloseModalMailing}>
              <i className='bx bx-layer-minus bx-fw' ></i> Отмена
            </Button>
            <Button onClick={() => addMailing()} variant="primary" className="btn-bl" >
              <i className='bx bx-check bx-fw'></i> Добавить
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showModalDelete}
          onHide={handleCloseModalDelete}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Удалить рассылку?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Вы действительно хотите удалить рассылку <strong>{deleteTitle}</strong>? После подтверждения, данное действие отменить будет невозможно.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" className="outline-btn-bl" onClick={handleCloseModalDelete}>
              <i className='bx bx-layer-minus bx-fw' ></i> Отмена
            </Button>
            <Button variant="primary" className="btn-bl" onClick={() => removeMailing(deleteId)}>
              <i className='bx bx-check bx-fw'></i> Удалить
            </Button>
          </Modal.Footer>
        </Modal>
        <Breadcrumb>
          <Link to="/" className="breadcrumb-item">
            Главная
          </Link>
          <Breadcrumb.Item active>Рассылки</Breadcrumb.Item>
        </Breadcrumb>
        <div className="shadow-sm p-4 bg-white rounded">
          <h5 className="pb-2">Рассылки</h5>
          <a onClick={() => handleShowModalMailing()}><Button className="mb-2 btn-bl"><i className='bx bx-fw bxs-file-plus' ></i> Создать рассылку</Button></a>
          <table className="admintable mb-2">
          <thead>
            <tr>
              <th>Название</th>
              <th>Тип рассылки</th>
              <th>Аудитория</th>
              <th>Дата отправки</th>
              <th>Действия</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item) => {
              return (
                <tr key={item._id}>
                <td data-label="Название">{item.title}</td>
                <td data-label="Тип рассылки">{item.type === 0 ? `Новости` : item.type === 1 ? `Продукты` : item.type === 2 ? `Системные` : `Не определено`}</td>
                <td data-label="Аудитория">{item.auditory === 0 ? `Пользователи` : item.auditory === 1 ? `Практики` : item.auditory === 2 ? `Мастера` : item.auditory === 3 ? `Все пользователи` : `Не определено`}</td>
                <td data-label="Дата отправки">{("0" + new Date(item.datePost).getDate()).slice(-2)+'.'+("0"+(new Date(item.datePost).getMonth()+1)).slice(-2)+'.'+new Date(item.datePost).getFullYear() + ' ' + ("0"+(new Date(item.datePost).getHours())).slice(-2) + ':' + ("0"+(new Date(item.datePost).getMinutes())).slice(-2)}</td>
                <td>
                  <div className={"inline"}>
                    <a className="ico-btn ico-trash" onClick={() => handleShowModalDelete(item._id, item.title)}><i className='bx bx-trash bx-fw' ></i></a>
                  </div>
                </td>
                </tr>
              )
            })}
            {arhiveItems.map((arhiveItem) => {
              return (
                <tr key={arhiveItem._id} style={{backgroundColor: '#E6E8E6'}}>
                <td data-label="Название">{arhiveItem.title}</td>
                <td data-label="Тип рассылки">{arhiveItem.type === 0 ? `Новости` : arhiveItem.type === 1 ? `Продукты` : arhiveItem.type === 2 ? `Системные` : `Не определено`}</td>
                <td data-label="Аудитория">{arhiveItem.auditory === 0 ? `Пользователи` : arhiveItem.auditory === 1 ? `Практики` : arhiveItem.auditory === 2 ? `Мастера` : arhiveItem.auditory === 3 ? `Все пользователи` : `Не определено`}</td>
                <td data-label="Дата отправки">{("0" + new Date(arhiveItem.datePost).getDate()).slice(-2)+'.'+("0"+(new Date(arhiveItem.datePost).getMonth()+1)).slice(-2)+'.'+new Date(arhiveItem.datePost).getFullYear() + ' ' + ("0"+(new Date(arhiveItem.datePost).getHours())).slice(-2) + ':' + ("0"+(new Date(arhiveItem.datePost).getMinutes())).slice(-2)}</td>
                <td>Отправлено</td>
                </tr>
              )
            })}
            </tbody>
          </table> 
        </div>
      </div>
    );
};

export default MailingPage;