import React, { useState, useContext, useEffect } from 'react';
import { observer } from "mobx-react-lite";
import { Breadcrumb, Tab, Row, Col, ListGroup, Form, Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'boxicons/css/boxicons.css';
import 'boxicons';
import successNotify from '../success-notify';
import errorNotify from '../error-notify';
import axios from 'axios';
import { API_URL } from '../../http';
import {Context} from '../../index';
import InputGroup from 'react-bootstrap/InputGroup';




const FaqPage = () => {

  const {store} = useContext(Context);
  const [items, setItems] = useState([]);

  const [creatorEmail, setCreatorEmail] = useState('');
  const [reason, setReason] = useState('');
  const [creatorId, setCreatorId] = useState('');

  const [idEdit, setIdEdit] = useState('');
  const [titleEdit, setTitleEdit] = useState('');
  const [actualEdit, setActualEdit] = useState(false);
  const [inDateEdit, setInDateEdit] = useState('');
  const [leadingEdit, setLeadingEdit] = useState('');
  const [placeEdit, setPlaceEdit] = useState('');

  const [title, setTitle] = useState('');
  const [actual, setActual] = useState(false);
  const [inDate, setInDate] = useState('');
  const [leading, setLeading] = useState('');
  const [place, setPlace] = useState('');


  const [showModalDelete, setShowModalDelete] = useState(false);
  const [cancelModalSchedule, setCancelModalSchedule] = useState(false);
  const [modalId, setModalId] = useState('');
  const [modalName, setModalName] = useState('');

  const [validated, setValidated] = useState(false);
  const [validatedEdit, setValidatedEdit] = useState(false);

  const handleCloseModalSchedule = () => setCancelModalSchedule(false);

  const handleShowModalSchedule = (creator) => {
    setCreatorId(creator);
    setCancelModalSchedule(true);
  }

  const handleCloseModalDelete = () => setShowModalDelete(false);

  const handleShowModalDelete = (id, name) => {
    setModalId(id);
    setModalName(name);
    setShowModalDelete(true);
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
  setValidated(true);
  createFaq();
  };

  const handleSubmitEdit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
  setValidatedEdit(true);
  editFaq();
  };


  const createFaq = () => {
    if (title == '' || inDate == '' || leading == '' || place == '') {
      return errorNotify('Заполните все поля расписания!');
    }
    const formData = new FormData()
    formData.append('title', title)
    formData.append('inDate', inDate)
    formData.append('leading', leading)
    formData.append('place', place)
    formData.append('actual', actual)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/schedule/create', formData)
    .then(res => {
      successNotify(`Расписание добавлено!`);
      addLog(`Добавил расписание №${res.data._id}`);
      getFaq();
    })
    .catch(err => {
      errorNotify(err.response.data.message);
    });
  }

  const editFaq = () => {
    if (titleEdit == '' || inDateEdit == '' || leadingEdit == '' || placeEdit == '') {
      return errorNotify('Заполните все поля расписания!');
    }
    const formData = new FormData()
    formData.append('_id', idEdit)
    formData.append('title', titleEdit)
    formData.append('inDate', inDateEdit)
    formData.append('leading', leadingEdit)
    formData.append('place', placeEdit)
    formData.append('actual', actualEdit)


    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/schedule/update', formData)
    .then(res => {
      successNotify(`Расписание обновлено!`);
      addLog(`Отредактировал расписание №${idEdit}`);
      getFaq();
    })
    .catch(err => {
      errorNotify(err.response.data.message);
    });
  }

  async function addLog(description) {
    const formData = new FormData()
    formData.append('username', store.user.email)
    formData.append('description', description)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/l/create', formData)
    .then(res => {
      console.log(res);
    })
    .catch(err => console.log(err));
  }

  async function deleteFaq(_id) {
    const formData = new FormData()
    formData.append('_id', _id)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/schedule/delete', formData)
    .then(res => {
      successNotify('Расписание удалено!');
      addLog(`Удалил расписание №${idEdit}`);
      handleCloseModalDelete();
      getFaq();
    })
    .catch(err => {
      handleCloseModalDelete();
      errorNotify(err.response.data.message);
    });
  }

  async function verifySchedule(creator, email) {
    const formData = new FormData()
    formData.append('creator', creator)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/schedule/verify', formData)
    .then(res => {
      successNotify('Расписание согласовано!');
      addLog(`Согласовал расписание у пользователя ${email}`);
      getFaq();
    })
    .catch(err => {
      errorNotify(err.response.data.message);
    });
  }

  async function cancelSchedule() {
    const formData = new FormData()
    if (reason === '') return errorNotify('Заполните поле причины отклонения!');
    formData.append('creator', creatorId)
    formData.append('reason', reason)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/schedule/cancel', formData)
    .then(res => {
      successNotify('Расписание отклонено!');
      addLog(`Отклонил расписание у пользователя ${creatorEmail}`);
      handleCloseModalSchedule();
      getFaq();
    })
    .catch(err => {
      setReason('');
      handleCloseModalSchedule();
      errorNotify(err.response.data.message);
    });
  }

  async function applyInfo(_id, title, dateIn, leading, place, actual, creator) {
    setIdEdit(_id);
    setTitleEdit(title);
    setInDateEdit(dateIn);
    setLeadingEdit(leading);
    setPlaceEdit(place);
    setActualEdit(actual);
    if(creator !== "admin") getEmail(creator);
  }

  const getFaq = async () => {
    const res = await fetch(
      API_URL + `/schedule`
    );
    const data = await res.json();
    setItems(data);
  };

  const getEmail = async (creator) => {
    const res = await fetch(
      API_URL + `/u/practicles/${creator}`
    );
    const data = await res.json();
    setCreatorEmail(data.email);
    return data.email;
  };

  useEffect(() => {
    getFaq();
  }, [setItems]);

    return (
      <div className="pt-2">
      <Modal
        show={showModalDelete}
        onHide={handleCloseModalDelete}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Удалить расписание?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Вы действительно хотите удалить расписание <strong>{modalName}</strong>? После подтверждения, данное действие отменить будет невозможно.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" className="outline-btn-bl" onClick={handleCloseModalDelete}>
            <i className='bx bx-layer-minus bx-fw' ></i> Отмена
          </Button>
          <Button variant="primary" className="btn-bl" onClick={() => deleteFaq(modalId)}>
            <i className='bx bx-check bx-fw'></i> Удалить
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal
        show={cancelModalSchedule}
        onHide={handleCloseModalSchedule}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Отклонить модерацию курса?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Вы действительно хотите отклонить модерацию курса "Сияющие руки" пользователя <strong>{creatorEmail}</strong>? После подтверждения, данное действие отменить будет невозможно.
          <InputGroup className="mt-2" >
            <Form.Control
                required
                as="textarea"
                style={{ height: '200px' }}
                value={reason}
                placeholder="Причина отказа"
                onChange={e => setReason(e.target.value)}
            />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" className="outline-btn-bl" onClick={handleCloseModalSchedule}>
            <i className='bx bx-layer-minus bx-fw' ></i> Отмена
          </Button>
          <Button variant="primary" className="btn-bl" onClick={() => cancelSchedule()}>
            <i className='bx bx-check bx-fw'></i> Удалить
          </Button>
        </Modal.Footer>
      </Modal>





        
      <Breadcrumb>
        <Link className="breadcrumb-item" to="/">
          Главная
        </Link>
        <Breadcrumb.Item active>Расписание</Breadcrumb.Item>
      </Breadcrumb>
        <div className="shadow-sm p-4 bg-white rounded">
          <h5 className="pb-2">Расписание</h5>
          <Tab.Container defaultActiveKey="#link1">
            <Row>
              <Col sm={4}>
                <ListGroup className="bl-list-group">
                  {items.map((item) => {
                    return (
                    <ListGroup.Item key={item._id} action href={`#` + item._id} onClick={() => applyInfo(item._id, item.title, item.inDate, item.leading, item.place, item.actual, item.creator)}>
                      {item.creator !== 'admin' ? <i className='bx bxs-user-rectangle bx-fw' ></i> : <i className='bx bxs-calendar-check bx-fw' ></i>} {item.title} {item.verify === false ? <i className="bx bx-fw bx-error text-warning bx-flashing"></i> : null}
                      <div className="position-absolute d-inline end-0 px-2"><i onClick={() => handleShowModalDelete(item._id, item.title)} className='bx bx-trash bx-fw' ></i></div>
                    </ListGroup.Item>
                    )
                  })}
                </ListGroup>
              </Col>
              <Col sm={8}>
                <Tab.Content>
                  {items.map((item) => {
                    return (
                    <Tab.Pane key={item._id} eventKey={`#` + item._id}>
                      <h5>Расписание: {item._id}</h5>
                      <Form noValidate validated={validatedEdit}>
                        <Row className="mb-3">
                          <Form.Group as={Col} md="12" controlId="validationCustom02">
                            <Form.Label>Создано пользователем</Form.Label>
                            <Form.Control
                              disabled
                              type="text"
                              placeholder="Пользователь"
                              value={creatorEmail === null ? "Из панели управления Bubble CMS" : creatorEmail === undefined ? "Из панели управления Bubble CMS" : creatorEmail === "Admin" ? "Из панели управления Bubble CMS" : creatorEmail}
                            />
                            <Form.Control.Feedback>
                              Название введено
                            </Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                              Введите название
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group as={Col} md="12" controlId="validationCustom02">
                            <Form.Label>Название</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="Название"
                              value={titleEdit}
                              onChange={e => setTitleEdit(e.target.value)}
                            />
                            <Form.Control.Feedback>
                              Название введено
                            </Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                              Введите название
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group as={Col} md="12" controlId="validationCustom03">
                            <Form.Label>Дата</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Дата"
                              required
                              value={inDateEdit}
                              onChange={e => setInDateEdit(e.target.value)}
                            />
                            <Form.Control.Feedback>
                              Дата введена
                            </Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                              Введите дату
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group as={Col} md="12" controlId="validationCustom03">
                            <Form.Label>Ведущий</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Ведущий"
                              required
                              value={leadingEdit}
                              onChange={e => setLeadingEdit(e.target.value)}
                            />
                            <Form.Control.Feedback>
                              Ведущий введен
                            </Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                              Введите ведущего
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group as={Col} md="12" controlId="validationCustom03">
                            <Form.Label>Место проведения</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Место проведения"
                              required
                              value={placeEdit}
                              onChange={e => setPlaceEdit(e.target.value)}
                            />
                            <Form.Control.Feedback>
                              Место проведения введено
                            </Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                              Введите Место проведения
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group as={Col} md="12" controlId="validationCustom03">
                            <Form.Label>Статус</Form.Label>
                            <Form.Select 
                              aria-label="Default select example"
                              defaultValue={item.actual}
                              onChange={e => setActualEdit(e.target.value)}
                              >
                              <option value="false">Запись закрыта</option>
                              <option value="true">Запись открыта</option>
                            </Form.Select>
                          <Form.Control.Feedback>
                              Статус записи выбран
                            </Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                              Выберите статус записи
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        <Button className="btn-bl" onClick={handleSubmitEdit}><i className='bx bx-fw bx-edit' ></i> Изменить расписание</Button>
                        {item.verify === false ? <Button className="btn-bl ms-2" onClick={() => verifySchedule(item.creator, creatorEmail)}><i className='bx bx-fw bx-check' ></i> Согласовать</Button> : null}
                        {item.verify === false ? <Button className="btn-bl ms-2" onClick={() => handleShowModalSchedule(item.creator)}><i className='bx bx-fw bx-message-rounded-dots' ></i> Отказать с примечанием</Button> : null}
                      </Form>
                    </Tab.Pane>
                    )
                  })}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
        <div className="shadow-sm p-4 bg-white rounded mt-2">
          <h5 className="pb-2">Добавить расписание</h5>
          <Form noValidate validated={validated}>
            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="validationCustom02">
                <Form.Label>Название</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Название"
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                />
                <Form.Control.Feedback>
                  Название введено
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Введите название
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="12" controlId="validationCustom03">
              <Form.Label>Дата</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Дата"
                  required
                  value={inDate}
                  onChange={e => setInDate(e.target.value)}
                />
                <Form.Control.Feedback>
                  Дата введена
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Введите дату
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="12" controlId="validationCustom03">
                <Form.Label>Ведущий</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ведущий"
                  required
                  value={leading}
                  onChange={e => setLeading(e.target.value)}
                />
                <Form.Control.Feedback>
                  Ведущий введен
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Введите ведущего
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="12" controlId="validationCustom03">
                <Form.Label>Место проведения</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Место проведения"
                  required
                  value={place}
                  onChange={e => setPlace(e.target.value)}
                />
                <Form.Control.Feedback>
                  Место проведения введено
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Введите Место проведения
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="12" controlId="validationCustom03">
                <Form.Label>Статус</Form.Label>
                <Form.Select 
                  aria-label="Default select example"
                  defaultValue={actual}
                  onChange={e => setActual(e.target.value)}
                  >
                  <option value={actual}>Запись закрыта</option>
                  <option value="true">Запись открыта</option>
                </Form.Select>
                <Form.Control.Feedback>
                  Статус записи выбран
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Выберите статус записи
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Button className="btn-bl" onClick={handleSubmit}><i className='bx bx-fw bx-layer-plus' ></i> Добавить расписание</Button>
          </Form>
        </div>
      </div>
    );
};

export default observer(FaqPage);