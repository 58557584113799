//import { observer } from "mobx-react-lite";
import React from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import IndexPage from './pages/index-page';
import NewsPage from "./components/pages/news-page";
import AddNewsPage from "./components/pages/addnews-page";
import EditNewsPage from "./components/pages/editnews-page";
import StaticPage from "./components/pages/static-page";
import ModulePage from "./components/pages/module-page";
import SettingsPage from "./components/pages/settings-page";
import UsersPage from "./components/pages/users-page";
import AboutPage from "./components/pages/about-page";
import SupportPage from "./components/pages/support-page";
import UserChart from './components/users-chart';
import DashboardPage from './components/pages/dashboard-page';
import ErrorPage from './components/pages/error-page';
import ProfilePage from "./components/pages/profile-page";
import AddPagesPage from "./components/pages/addpages-page";
import EditStaticPage from "./components/pages/editstatic-page";
import LogsPage from './components/pages/logs-page';
import TicketsPage from "./components/pages/tickets-page";
import FaqPage from './components/pages/faq-page';
import CountryPage from "./components/pages/country-page";
import CoursesPage from "./components/pages/courses-page";
import BooksPage from "./components/pages/books-page";
import AddBooksPage from "./components/pages/addbooks-page";
import EditBooksPage from "./components/pages/editbooks-page";
import Media from "./components/pages/media-page";
import MailingPage from "./components/pages/mailing-page";
import LandsPage from "./components/pages/lands-page";
import AddLandsPage from "./components/pages/addlands-page";
import EditLandsPage from "./components/pages/editlands-page";
import VideosPage from "./components/pages/videos-page";
import PaymentsPage from "./components/pages/payments-page";
import RegisterPage from "./components/pages/register-page";

function App() {
  return (
    <Router>
    <Routes>
      <Route element={<IndexPage />}>
        <Route path="/" element={<DashboardPage />} />
        <Route path="/news" element={<NewsPage />} />
        <Route path="/lands" element={<LandsPage />} />
        <Route path="/news/add" element={<AddNewsPage />} />
        <Route path="/news/edit/:id" element={<EditNewsPage />} />
        <Route path="/lands/edit/:id" element={<EditLandsPage />} />
        <Route path="/lands/add" element={<AddLandsPage />} />
        <Route path="/payments" element={<PaymentsPage />} />
        <Route path="/pages" element={<StaticPage />} />
        <Route path="/pages/add" element={<AddPagesPage />} />
        <Route path="/pages/edit/:id" element={<EditStaticPage />} />
        <Route path="/module" element={<ModulePage />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/users" element={<UsersPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/country" element={<CountryPage />} />
        <Route path="/support" element={<SupportPage />} />
        <Route path="/profile" element={<ProfilePage />}/>
        <Route path="/logs" element={<LogsPage />}/>
        <Route path="/tickets" element={<TicketsPage />}/>
        <Route path="/registers" element={<RegisterPage />} />
        <Route path="/schedule" element={<FaqPage />}/>
        <Route path="/courses" element={<CoursesPage/>}/>
        <Route path="/books" element={<BooksPage/>}/>
        <Route path="/books/add" element={<AddBooksPage />} />
        <Route path="/books/edit/:id" element={<EditBooksPage />} />
        <Route path="/media" element={<Media />} />
        <Route path="/mailing" element={<MailingPage />} />
        <Route path="/video" element={<VideosPage />} />
      </Route>
      <Route element={<IndexPage />}>
        <Route path="*" element={<ErrorPage />}></Route>
      </Route>
    </Routes>
  </Router>)
}

export default App;