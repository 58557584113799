import React, {useContext, useState, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import {Table} from 'react-bootstrap';
import 'boxicons/css/boxicons.css';
import 'boxicons';
import {Breadcrumb} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import axios from 'axios';
import {Modal, Button} from 'react-bootstrap';
import '../../style.css';
import successNotify from '../success-notify';
import errorNotify from '../error-notify';
import {Context} from '../../index';
import { API_URL } from '../../http';


const LandsPage = () => {
  const [items, setItems] = useState([]);
  const {store} = useContext(Context);

  const [showModalNews, setShowModalNews] = useState(false);

  const [deleteNewsId, setDeleteNewsId] = useState('');
  const [deleteNewsTitle, setDeleteNewsTitle] = useState('');

  const handleCloseModalNews = () => setShowModalNews(false);
  const handleShowModalNews = (_id, title) => {
    setDeleteNewsId(_id);
    setDeleteNewsTitle(title);
    setShowModalNews(true);
  }


  const getNews = async () => {
    const res = await fetch(
      API_URL + `/lands/all`, {
        headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
       });
    const data = await res.json();
    setItems(data);
  };

  useEffect(() => {
    getNews();
  }, [setItems]);

  async function addLog(description) {
    const formData = new FormData()
    formData.append('username', store.user.email)
    formData.append('description', description)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/l/create', formData)
    .then(res => {
      console.log(res);
    })
    .catch(err => console.log(err));
  }

  async function removeNews(_id) {
    const formData = new FormData()
    formData.append('_id', _id)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/lands/delete', formData)
    .then(res => {
      handleCloseModalNews();
      successNotify(`Курс №` + _id + ` была удалена!`);
      addLog(`Удалил Курс № ${_id}`);
      getNews();
    })
    .catch(err => console.log(err));
  }

  async function publishNews(_id) {
    const formData = new FormData()
    formData.append('_id', _id)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/lands/publish', formData)
    .then(res => {
      successNotify(`Курс №` + _id + ` была опубликована!`);
      addLog(`Опубликовал Курс № ${_id}`);
      getNews();
    })
    .catch(err => console.log(err));
  }

  async function unPublishNews(_id) {
    const formData = new FormData()
    formData.append('_id', _id)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/lands/unpublish', formData)
    .then(res => {
      successNotify(`Курс №` + _id + ` снята с публикации!`);
      addLog(`Снял с публикации Курс № ${_id}`);
      getNews();
    })
    .catch(err => console.log(err));
  }


    return (
      <div className="pt-2">
        <Modal
          show={showModalNews}
          onHide={handleCloseModalNews}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Удалить Курс?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Вы действительно хотите удалить Курс <strong>{deleteNewsTitle}</strong>? После подтверждения, данное действие отменить будет невозможно.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" className="outline-btn-bl" onClick={handleCloseModalNews}>
              <i className='bx bx-layer-minus bx-fw' ></i> Отмена
            </Button>
            <Button variant="primary" className="btn-bl" onClick={() => removeNews(deleteNewsId)}>
              <i className='bx bx-check bx-fw'></i> Удалить
            </Button>
          </Modal.Footer>
        </Modal>
        <Breadcrumb>
          <Link to="/" className="breadcrumb-item">
            Главная
          </Link>
          <Breadcrumb.Item active>Курсы</Breadcrumb.Item>
        </Breadcrumb>
        <div className="shadow-sm p-4 bg-white rounded">
          <h5 className="pb-2">Курсы</h5>
          <Link to="/lands/add"><Button className="mb-2 btn-bl"><i className='bx bx-fw bxs-file-plus' ></i> Создать курс</Button></Link>
          <table className="admintable mb-2">
          <thead>
            <tr>
              <th>Заголовок</th>
              <th>Ссылка</th>
              <th>Статус публикации</th>
              <th>Действия</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item) => {
              return (
                <tr key={item._id}>
                <td data-label="Заголовок">{item.title}</td>
                <td data-label="Ссылка">{item.linkCourse}</td>
                <td data-label="Статус публикации">{item.published ? "Опубликовано" : "Не опубликовано"}</td>
                <td>
                  <div className={"inline"}>
                    <Link className="ico-btn ico-edit" to={`/lands/edit/${item._id}`}><i className='bx bx-edit bx-fw' ></i></Link>
                    <a className="ico-btn ico-visible" onClick={() => item.published? unPublishNews(item._id) : publishNews(item._id)}>
                      <i className={item.published ? "bx bx-low-vision bx-fw" : "bx bxs-low-vision bx-fw"}>
                      </i>
                    </a>
                    <a className="ico-btn ico-trash" onClick={() => handleShowModalNews(item._id, item.title)}><i className='bx bx-trash bx-fw' ></i></a>
                  </div>
                </td>
                </tr>
              )
            })}
            </tbody>
          </table> 
        </div>
      </div>
    );
};

export default LandsPage;