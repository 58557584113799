import React, {useContext, useState, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import {Table} from 'react-bootstrap';
import 'boxicons/css/boxicons.css';
import 'boxicons';
import {Breadcrumb} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import axios from 'axios';
import Pagination from 'react-bootstrap/Pagination';
import {Modal, Button} from 'react-bootstrap';
import ReactPaginate from "react-paginate";
import '../../style.css';
import successNotify from '../success-notify';
import errorNotify from '../error-notify';
import {Context} from '../../index';
import { API_URL } from '../../http';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ReactPlayer from 'react-player'
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form'

const VideosPage = () => {
  const [items, setItems] = useState([]);
  const [title, setTitle] = useState('');
  const [published, setPublished] = useState(false);
  const [showModalMedia, setShowModalMedia] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [deleteTitle, setDeleteTitle] = useState('');
  const [deleteId, setDeleteId] = useState('');
  const [deleteLink, setDeleteLink] = useState('');
  const {store} = useContext(Context);
  const [file, setFile] = useState('');


  const handleCloseModalMedia = () => setShowModalMedia(false);
  const handleShowModalMedia = () => {
    setShowModalMedia(true);
  }

  const handleCloseModalDelete = () => setShowModalDelete(false);
  const handleShowModalDelete = (did, dtitle, dlink) => {
    setDeleteId(did);
    setDeleteTitle(dtitle);
    setDeleteLink(dlink);
    setShowModalDelete(true)
  }

  const getMediaFiles = async () => {
    const res = await fetch(
      API_URL + `/videos/all`, {
      headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
     });
    const data = await res.json();
    setItems(data);
  };

  const togglePublish = (id, title) => {
    const formData = new FormData()
    formData.append('_id', id)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/videos/publish', formData)
    .then(res => {
       successNotify(`Видео ${deleteTitle} было обновлено!`);
       addLog(`Обновил статус публикации видео ${deleteTitle}`);
       getMediaFiles();
    })
    .catch(err => console.log(err));
}

const untogglePublish = (id, title) => {
  const formData = new FormData()
  formData.append('_id', id)
  axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
  axios.post(API_URL + '/videos/unpublish', formData)
  .then(res => {
     successNotify(`Видео ${deleteTitle} было обновлено!`);
     addLog(`Обновил статус публикации видео ${deleteTitle}`);
     getMediaFiles();
  })
  .catch(err => console.log(err));
}

  useEffect(() => {
    getMediaFiles();
  }, [setItems]);

  async function addLog(description) {
    const formData = new FormData()
    formData.append('username', store.user.email)
    formData.append('description', description)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/l/create', formData)
    .then(res => {
      console.log(res);
    })
    .catch(err => console.log(err));
  }

  const addMediaFile = () => {
    if (title == '' || file == '') {
        return errorNotify(`Необходимо заполнить все поля!`);
    }
    const formData = new FormData()
    formData.append('title', title)
    formData.append('link', file)
    formData.append('published', published)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/videos/create', formData)
    .then(res => {
       successNotify(`Видео ${res.data.title} был добавлен!`);
       addLog(`Добавил видео ${res.data.title}`);
       setFile('');
       setTitle('');
       getMediaFiles();
       handleCloseModalMedia();
    })
    .catch(err => console.log(err));

}

const deleteMediaFile = (id, link) => {
    const formData = new FormData()
    formData.append('_id', id)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/videos/delete', formData)
    .then(res => {
       successNotify(`Видео ${deleteTitle} был удален!`);
       addLog(`Удалил видео ${deleteTitle}`);
       setDeleteId('');
       setDeleteTitle('');
       setDeleteLink('');
       getMediaFiles();
       handleCloseModalDelete();
    })
    .catch(err => console.log(err));
}

    return (
      <div className="pt-2">

        <Modal
          show={showModalMedia}
          onHide={handleCloseModalMedia}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Добавление видео</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Control
                placeholder="Введите название видео"
                aria-label="Введите название видео"
                aria-describedby="basic-addon2"
                value={title}
                onChange={e => setTitle(e.target.value)}
                className="mb-2"
              />
              <Form.Control
                placeholder="Введите ссылку"
                aria-label="Введите ссылку"
                aria-describedby="basic-addon2"
                value={file}
                onChange={e => setFile(e.target.value)}
                className="mb-2"
              />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" className="outline-btn-bl" onClick={handleCloseModalMedia}>
              <i className='bx bx-layer-minus bx-fw' ></i> Отмена
            </Button>
            <Button variant="primary" className="btn-bl" onClick={() => addMediaFile()}>
              <i className='bx bx-check bx-fw'></i> Добавить
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showModalDelete}
          onHide={handleCloseModalDelete}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Удаление видео</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Вы действительно хотите удалить видео <strong>{deleteTitle}</strong>? После подтверждения, данное действие отменить будет невозможно.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" className="outline-btn-bl" onClick={handleCloseModalDelete}>
              <i className='bx bx-layer-minus bx-fw' ></i> Отмена
            </Button>
            <Button variant="primary" className="btn-bl" onClick={() => deleteMediaFile(deleteId, deleteLink)}>
              <i className='bx bx-check bx-fw'></i> Удалить
            </Button>
          </Modal.Footer>
        </Modal>

        <Breadcrumb>
          <Link to="/" className="breadcrumb-item">
            Главная
          </Link>
          <Breadcrumb.Item active>Видео</Breadcrumb.Item>
        </Breadcrumb>
        <div className="shadow-sm p-4 bg-white rounded">
          <h5 className="pb-2">Видео</h5>
          <a onClick={handleShowModalMedia}><Button className="mb-2 btn-bl"><i className='bx bx-fw bxs-videos' ></i> Добавить видео</Button></a>
            <Row xs={1} md={3} className="g-4">
            {items.map((item) => {
                return (
                <Col key={item._id}>
                    <Card>
                        <Card.Header>
                            <Nav>
                                <Nav.Item>
                                    <button onClick={() => handleShowModalDelete(item._id, item.title, item.link)} type="button" id="button-addon2" className="outline-btn-bl btn btn-outline-primary"><i className="bx bx-fw bx-lock-open-alt"></i> Удалить медиа</button>
                                </Nav.Item>
                                <Nav.Item className="ms-xl-2 ms-lg-0 mt-lg-2 mt-md-2 ms-md-2 ms-sm-2 ms-2 mt-xl-0 mt-lg-0">
                                    <button onClick={item.published === true ? () => untogglePublish(item._id, item.title) : () => togglePublish(item._id, item.title)} type="button" id="button-addon2" className="outline-btn-bl btn btn-outline-primary mr-2"><i className="bx bx-fw bx-lock-open-alt"></i> {item.published === true ? `Снять с публикации` : `Опубликовать`}</button>
                                </Nav.Item>
                            </Nav>
                        </Card.Header>
                        <ReactPlayer
                            // Disable download button
                            config={{ file: { attributes: { controlsList: 'nodownload' } } }}

                            // Disable right click
                            onContextMenu={e => e.preventDefault()}

                            // Your props
                            url={item.link}
                            className="react-player"
                            controls
                            width="100%"
                            height="100%"
                        />
                        <Card.Body>
                            <Card.Title>{item.title}</Card.Title>
                        </Card.Body>
                    </Card>
                </Col>
                )
                })}
            </Row>
        </div>
      </div>
    );
};

export default VideosPage;