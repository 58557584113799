import React, { useState, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import {Breadcrumb} from 'react-bootstrap';
import {render} from 'react-dom';
import {Link} from 'react-router-dom';
import '../../style.css';
import axios from 'axios';
import successNotify from '../success-notify';
import errorNotify from '../error-notify';
import {Context} from '../../index';
import {API_URL} from '../../http';

function AddLandsPage() {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    addPages();
  };

  const [title, setTitle] = useState('')
  const [file, setFile] = useState(null)

  const [published, setPublished] = useState('false');

  const [description, setDescription] = useState('');
  const [formatCourse, setFormatCourse] = useState('');
  const [modelCourse, setModelCourse] = useState('');
  const [typeCourse, setTypeCourse] = useState(false);
  const [linkCourse, setLinkCourse] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [dateStart, setDateStart] = useState('');
  const [dateFinish, setDateFinish] = useState('');
  const [costFull, setCostFull] = useState('');
  const [costDeposit, setConstDeposit] = useState('');

  const [imgTitle, setImgTitle] = useState('Выбрать файл...');
  const {store} = useContext(Context);

  const selectFile = e => {
      setFile(e.target.files[0])
      if (e.target.files[0] == null){
          setImgTitle(null);
      }
      else setImgTitle(e.target.files[0].name);
  }

  async function addLog(description) {
    const formData = new FormData()
    formData.append('username', store.user.email)
    formData.append('description', description)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/l/create', formData)
    .then(res => {
      console.log(res);
    })
    .catch(err => console.log(err));
  }

  const addPages = () => {
      if (title === '' || description === '' || costDeposit === '' || costFull === '' || dateStart === '' || dateFinish === '') {
          return errorNotify(`Необходимо заполнить все поля!`);
      }
      if (file == null) {
          setFile(null);
      }
      const formData = new FormData()
      if (typeCourse === '') return errorNotify('Выберите тип курса!');
      formData.append('title', title)
      formData.append('img', file)
      formData.append('description', description)
      formData.append('published', published)
      formData.append('formatCourse', formatCourse)
      formData.append('typeCourse', typeCourse)
      formData.append('modelCourse', modelCourse)
      formData.append('linkCourse', linkCourse)
      formData.append('isActive', isActive)
      formData.append('dateStart', dateStart)
      formData.append('dateFinish', dateFinish)
      formData.append('costFull', costFull)
      formData.append('costDeposit', costDeposit)

      axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
      axios.post(API_URL + '/lands/create', formData)
      .then(res => {
         successNotify(`Курс № ${res.data._id} был создан!`);
         addLog(`Создал курс № ${res.data._id}`);
      })
      .catch(err => console.log(err));

  }


  return (
    <div className="pt-2">
    <Breadcrumb>
      <Link className="breadcrumb-item" to="/">
        Главная
      </Link>
      <Link className="breadcrumb-item" to="/lands">
        Курсы
      </Link>
      <Breadcrumb.Item active>Добавить курс</Breadcrumb.Item>
    </Breadcrumb>
      <div className="shadow-sm p-4 bg-white rounded">
        <h5 className="pb-2">Добавить курс</h5>
        <Form noValidate validated={validated}>
          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="validationCustom01">
              <Form.Label>Заголовок</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Заголовок курса"
                value={title}
                onChange={e => setTitle(e.target.value)}
              />
              <Form.Control.Feedback>
                Заголовок заполнен
              </Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                  Заполните заголовок
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustom02">
              <Form.Label>Выберите изображение</Form.Label>
              <Form.Control 
                required
                type="file"
                onChange={selectFile}
              />
              <Form.Control.Feedback>Изображение выбрано</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Выберите изображение
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustomUsername">
              <Form.Label>Статус публикации</Form.Label>
              <Form.Select 
                aria-label="Default select example"
                defaultValue={published}
                onChange={e => setPublished(e.target.value)}
              >
                <option value={published}>Не публиковать курс</option>
                <option value="true">Опубликовать курс</option>
            </Form.Select>
            <Form.Control.Feedback>
              Статус публикации выбран
            </Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Выберите статус публикации
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="validationCustomUsername">
              <Form.Label>Тип мероприятия</Form.Label>
              <Form.Select 
                aria-label="Default select example"
                defaultValue={typeCourse}
                onChange={e => setTypeCourse(e.target.value)}
              >{
                typeCourse === true ?
                <>
                  <option value={false}>Курс</option>
                  <option selected value={true}>Семинар</option>
                </>
                :
                <>
                  <option selected value={false}>Курс</option>
                  <option value={true}>Семинар</option>
                </>
              }
             </Form.Select>
             <Form.Control.Feedback>
                Тип курса выбран
              </Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
               Выберите тип курса
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustom01">
              <Form.Label>Модель</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Модель курса"
                value={modelCourse}
                onChange={e => setModelCourse(e.target.value)}
              />
              <Form.Control.Feedback>
                Модель заполнена
              </Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                  Заполните модель
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustom01">
              <Form.Label>Формат</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Формат курса"
                value={formatCourse}
                onChange={e => setFormatCourse(e.target.value)}
              />
              <Form.Control.Feedback>
                Формат заполнен
              </Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                  Заполните формат
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
          <Form.Group as={Col} md="4" controlId="validationCustom01">
              <Form.Label>Ссылка</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Ссылка на курс"
                value={linkCourse}
                onChange={e => setLinkCourse(e.target.value)}
              />
              <Form.Control.Feedback>
                Ссылка заполнена
              </Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                  Заполните ссылку
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustomUsername">
              <Form.Label>Покупка курса</Form.Label>
              <Form.Select 
                aria-label="Default select example"
                defaultValue={isActive}
                onChange={e => setIsActive(e.target.value)}
              >{
                isActive === true ?
                <>
                  <option value={false}>Выключено</option>
                  <option selected value={true}>Включено</option>
                </>
                :
                <>
                  <option selected value={false}>Выключено</option>
                  <option value={true}>Включено</option>
                </>
              }
             </Form.Select>
             <Form.Control.Feedback>
                Возможность купить курс выбрана
              </Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
               Выберите возможность покупки курса
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustom01">
              <Form.Label>Дата начала курса</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Дата начала курса"
                value={dateStart}
                onChange={e => setDateStart(e.target.value)}
              />
              <Form.Control.Feedback>
                Дата начала курса заполнена
              </Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Заполните дату начала курса!
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="validationCustom01">
              <Form.Label>Дата завершения курса</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Дата завершения курса"
                value={dateFinish}
                onChange={e => setDateFinish(e.target.value)}
              />
              <Form.Control.Feedback>
                Дата начала курса заполнена
              </Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Заполните дату начала курса!
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustom01">
              <Form.Label>Полная стоимость курса</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Полная стоимость курса"
                value={costFull}
                onChange={e => setCostFull(e.target.value)}
              />
              <Form.Control.Feedback>
                Полная стоимость курса заполнена
              </Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Заполните полную стоимость курса
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustom01">
              <Form.Label>Депозит стоимости курса</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Депозит стоимости курса"
                value={costDeposit}
                onChange={e => setConstDeposit(e.target.value)}
              />
              <Form.Control.Feedback>
                Депозит заполнен
              </Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Заполните депозит
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md="12" controlId="validationCustom04">
              <Form.Label>Описание</Form.Label>
              <Form.Control
                required
                as="textarea"
                style={{ height: '200px' }}
                value={description}
                onChange={e => setDescription(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Заполните описание
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Button className="btn-bl" onClick={handleSubmit}><i className='bx bx-fw bx-check bx-flashing' ></i> Создать курс</Button>
        </Form>
      </div>
    </div>
  );
}

export default (AddLandsPage);