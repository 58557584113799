import React, { useState, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import {Breadcrumb} from 'react-bootstrap';
import {render} from 'react-dom';
import {Link} from 'react-router-dom';
import '../../style.css';
import axios from 'axios';
import successNotify from '../success-notify';
import errorNotify from '../error-notify';
import {Context} from '../../index';
import {API_URL} from '../../http';

function AddBooksPage() {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    addPages();
  };

  const [title, setTitle] = useState('');
  const [file, setFile] = useState(null);
  const [description, setDescription] = useState('');
  const [autor, setAutor] = useState('');
  const [special, setSpecial] = useState('');
  const [cost, setCost] = useState('');
  const [published, setPublished] = useState('false');
  const [imgTitle, setImgTitle] = useState('Выбрать файл...');
  const {store} = useContext(Context);

  const selectFile = e => {
      setFile(e.target.files[0])
      if (e.target.files[0] == null){
          setImgTitle(null);
      }
      else setImgTitle(e.target.files[0].name);
  }

  async function addLog(description) {
    const formData = new FormData()
    formData.append('username', store.user.email)
    formData.append('description', description)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/l/create', formData)
    .then(res => {
      console.log(res);
    })
    .catch(err => console.log(err));
  }

  const addPages = () => {
      if (title == '' || description == '' || autor == '' || special == '' || cost == '') {
          return errorNotify(`Необходимо заполнить все поля!`);
      }
      if (file == null) {
          setFile(null);
      }
      const formData = new FormData()
      formData.append('title', title)
      formData.append('img', file)
      formData.append('description', description)
      formData.append('autor', autor)
      formData.append('special', special)
      formData.append('cost', cost)
      formData.append('published', published)
      axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
      axios.post(API_URL + '/b/create', formData)
      .then(res => {
         successNotify(`Книга № ${res.data.books_id} была создана!`);
         addLog(`Создал книгу № ${res.data.books_id}`);
      })
      .catch(err => console.log(err));

  }


  return (
    <div className="pt-2">
    <Breadcrumb>
      <Link className="breadcrumb-item" to="/">
        Главная
      </Link>
      <Link className="breadcrumb-item" to="/books">
        Книги
      </Link>
      <Breadcrumb.Item active>Добавить книгу</Breadcrumb.Item>
    </Breadcrumb>
      <div className="shadow-sm p-4 bg-white rounded">
        <h5 className="pb-2">Добавить книгу</h5>
        <Form noValidate validated={validated}>
          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="validationCustom01">
              <Form.Label>Название книги</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Название книги"
                value={title}
                onChange={e => setTitle(e.target.value)}
              />
              <Form.Control.Feedback>
                Название заполнено
              </Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                  Заполните название
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustom02">
              <Form.Label>Выберите изображение</Form.Label>
              <Form.Control 
                required
                type="file"
                onChange={selectFile}
              />
              <Form.Control.Feedback>Изображение выбрано</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Выберите изображение
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustomUsername">
              <Form.Label>Статус публикации</Form.Label>
              <Form.Select 
                aria-label="Default select example"
                defaultValue={published}
                onChange={e => setPublished(e.target.value)}
              >
                <option value={published}>Не публиковать книгу</option>
                <option value="true">Опубликовать книгу</option>
            </Form.Select>
            <Form.Control.Feedback>
              Статус публикации выбран
            </Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Выберите статус публикации
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="validationCustom01">
              <Form.Label>Автор книги</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Автор книги"
                value={autor}
                onChange={e => setAutor(e.target.value)}
              />
              <Form.Control.Feedback>
                Автор книги заполнен
              </Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                  Заполните автора книги
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustom01">
              <Form.Label>Материал и количество страниц</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Материал и количество страниц"
                value={special}
                onChange={e => setSpecial(e.target.value)}
              />
              <Form.Control.Feedback>
                Материал и количество страниц заполнены
              </Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                  Заполните материал и количество страниц
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustom01">
              <Form.Label>Стоимость книги</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Стоимость книги"
                value={cost}
                onChange={e => setCost(e.target.value)}
              />
              <Form.Control.Feedback>
                Стоимость заполнена
              </Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Заполните стоимость книги
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md="12" controlId="validationCustom04">
              <Form.Label>Описание книги</Form.Label>
              <Form.Control
                required
                as="textarea"
                style={{ height: '200px' }}
                value={description}
                onChange={e => setDescription(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Заполните полную книгу
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Button className="btn-bl" onClick={handleSubmit}><i className='bx bx-fw bx-check bx-flashing' ></i> Создать книгу</Button>
        </Form>
      </div>
    </div>
  );
}

export default (AddBooksPage);