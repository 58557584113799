import React, {useContext, useState, useEffect} from 'react';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import {Breadcrumb, Modal, Card, ListGroupItem, FormControl, ButtonToolbar, ButtonGroup} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import 'boxicons/css/boxicons.css';
import 'boxicons';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import axios from 'axios';
import errorNotify from '../error-notify';
import successNotify from '../success-notify';
import { API_URL } from '../../http';
import {Context} from "../../index";
import { IMG_URL } from '../../http';

const UsersPage = () => {

 const [updateLastName, setUpdateLastName] = useState('');
 const [updateFirstName, setUpdateFirstName] = useState('');
 const [updateSurName, setUpdateSurName] = useState('');
 const [updatePhone, setUpdatePhone] = useState('');
 const [updateVk, setUpdateVk] = useState('');
 const [updateTg, setUpdateTg] = useState('');
 const [updateWp, setUpdateWp] = useState('');
 const [updateRole, setUpdateRole] = useState('');
 const [updateDescription, setUpdateDescription] = useState('');
 const [updateRoleType, setUpdateRoleType] = useState('');
 const [updateVerify, setUpdateVerify] = useState('');

 const [updateUserRole, setUpdateUserRole] = useState('');
 const [updateUserType, setUpdateUserType] = useState('');



  const [city, setCity] = useState([]);
  const [country, setCountry] = useState([]);
  const [currentCountry, setCurrentCountry] = useState('');
  const [updateCountry, setUpdateCountry] = useState('');
  const [updateCity, setUpdateCity] = useState('');



  const [createCountry, setCreateCountry] = useState('');
  const [createCity, setCreateCity] = useState('');
  const [createUserType, setCreateUserType] = useState('');


  const [moder, setModer] = useState([]);



  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [activeKey, setActiveKey] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [newPassword1, setNewPassword1] = useState('');
  const [newPassword2, setNewPassword2] = useState('');
  const [createEmail, setCreateEmail] = useState('');
  const [createDesc, setCreateDesc] = useState('');
  const [createVK, setCreateVK] = useState('');
  const [createTg, setCreateTg] = useState('');
  const [createWp, setCreateWp] = useState('');
  const [createFirstname, setCreateFirstname] = useState('');
  const [createLastname, setCreateLastname] = useState('');
  const [createSurname, setCreateSurname] = useState('');
  const [createPhone, setCreatePhone] = useState('');
  const [createAddress, setCreateAddress] = useState('');
  const [createRole, setCreateRole] = useState(0);
  const [createVerify, setCreateVerify] = useState(false);
  const [imgTitle, setImgTitle] = useState('Выбрать файл...');
  const [file, setFile] = useState(null);
  const selectFile = e => {
    setFile(e.target.files[0])
    if (e.target.files[0] == null){
        setImgTitle(null);
    }
    else setImgTitle(e.target.files[0].name);
  }
  const [createPassword1, setCreatePassword1] = useState('');
  const [createPassword2, setCreatePassword2] = useState('');

  const [defaultUser, setDefaultUser] = useState('');



  const {store} = useContext(Context);

  const [selectedUser, setSelectedUser] = useState('');
  const [selectedUserID, setSelectedUserID] = useState('');

  const [showModalPassword, setShowModalPassword] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalCreate, setShowModalCreate] = useState(false);
  const [showModalUpdateCity, setShowModalUpdateCity] = useState(false);
  const [showModalUpdateRoles, setShowModalUpdateRoles] = useState(false);

  const handleCloseModalUpdateCity = () => {
    setUpdateCity('');
    setUpdateCountry('');
    setShowModalUpdateCity(false);
  }
  const handleShowModalUpdateCity = (id, name) => {
    setSelectedUserID(id);
    setSelectedUser(name);
    setShowModalUpdateCity(true);
  }

  const handleCloseModalUpdateRoles = () => {
    setUpdateUserRole('');
    setUpdateUserType('');
    setShowModalUpdateRoles(false);
  }
  const handleShowModalUpdateRoles = (id, name) => {
    setSelectedUserID(id);
    setSelectedUser(name);
    setShowModalUpdateRoles(true);
  }

  const handleCloseModalPassword = () => setShowModalPassword(false);
  const handleShowModalPassword = (id, name) => {
    setSelectedUserID(id);
    setSelectedUser(name);
    setShowModalPassword(true);
  }

  const handleCloseModalDelete = () => setShowModalDelete(false);
  const handleShowModalDelete = (id, name) => {
    setSelectedUserID(id);
    setSelectedUser(name);
    setShowModalDelete(true);
  }

  const handleCloseModalCreate = () => setShowModalCreate(false);
  const handleShowModalCreate = () => {
    setCreateEmail('');
    setCreatePassword1('');
    setCreatePassword2('');
    setShowModalCreate(true);
  }


  const getUsers = async () => {
    const res = await fetch(
      API_URL + `/u/all`, {
      headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
     });
    const data = await res.json();
    setUsers(data);
  };

  const getRoles = async () => {
    const res = await fetch(
      API_URL + `/u/allroles`, {
      headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
     });
    const data = await res.json();
    setRoles(data);
  };

  const getDefaultUser = async () => {
    const res = await fetch(
      API_URL + `/u/default`, {
        headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
       });
    const data = await res.json();
    setDefaultUser(data);
    setActiveKey(`#` + data);
    
  };

  useEffect(() => {
    getUsers();
  }, [setUsers]);

  useEffect(() => {
    getRoles();
  }, [setRoles]);

  useEffect(() => {
    getDefaultUser();
  }, [setActiveKey]);

  const getCountry = async () => {
    const res = await fetch(
      API_URL + `/c`
    );
    const data = await res.json();
    setCountry(data);
  };

  const getModer = async () => {
    const res = await fetch(
      API_URL + `/m/`, {
        headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
       });
    const data = await res.json();
    setModer(data);
  };

  const getCity = async () => {
    const res = await fetch(
      API_URL + `/c/city`
    );
    const data = await res.json();
    setCity(data);
  };

  const getDefaultCountry = async () => {
    const res = await fetch(
      API_URL + `/c/default`
    );
    const data = await res.json();
    setCurrentCountry(data._id);
  };

  useEffect(() => {
    getModer();
  }, [setModer]);

  useEffect(() => {
    getCountry();
  }, [setCountry]);

  useEffect(() => {
    getCity();
  }, [setCity]);

  useEffect(() => {
    getDefaultCountry();
  }, [setCurrentCountry]);

  async function addLog(description) {
    const formData = new FormData()
    formData.append('username', store.user.email)
    formData.append('description', description)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/l/create', formData)
    .then(res => {
    })
    .catch(err => errorNotify(err.response.data.message));
  }

  async function changeEmail(auid, uid, uemail) {
    if (newEmail == '' || newEmail == null) {
      return errorNotify(`Введите новый E-Mail пользователю!`)
    }
    const formData = new FormData()
    formData.append('auid', auid)
    formData.append('uid', uid)
    formData.append('newemail', newEmail)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/u/changeemail', formData)
    .then(res => {
      addLog(`Изменил E-Mail пользователю ${uemail} на ${newEmail}`);
      successNotify(`Пользователь ${newEmail} обновлен!`);
      getUsers();
    })
    .catch(err => errorNotify(err.response.data.message));
  }
  async function updateChanges(uid, onRole, ln, fn, sn, ph, vvk, vtg, vwp, dp) {
    const formData = new FormData()
    formData.append('auid', store.user.id)
    formData.append('uid', uid)
    if(updateLastName === '') {
      formData.append('lastname', ln)
    } else formData.append('lastname', updateLastName)
    if(updateFirstName === '') {
      formData.append('firstname', fn)
    } else formData.append('firstname', updateFirstName)
    if(updateSurName === '') {
      formData.append('surname', sn)
    } else formData.append('surname', updateSurName)
    if(updatePhone === '') {
      formData.append('phone', ph)
    } else formData.append('phone', updatePhone)
    if(updateVk === '') {
      formData.append('vk', vvk)
    } else formData.append('vk', updateVk)
    if(updateTg === '') {
      formData.append('tg', vtg)
    } else formData.append('tg', updateTg)
    if(updateWp === '') {
      formData.append('wp', vwp)
    } else formData.append('wp', updateWp)
    if(updateDescription === '') {
      formData.append('description', dp)
    } else formData.append('description', updateDescription)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/u/aupdate', formData)
    .then(res => {
      addLog(`Обновил данные пользователя ${uid}`);
      successNotify(`Данные пользователя обновлен!`);
      setUpdateFirstName('');
      setUpdateLastName('');
      setUpdateSurName('');
      setUpdatePhone('');
      setUpdateDescription('');
      setUpdateVk('');
      setUpdateTg('');
      setUpdateWp('');
      getUsers();
      getRoles();
    })
    .catch(err => errorNotify(err.response.data.message));
  }

  async function createUser() {
    if (createEmail == '' || createEmail == null) {
      return errorNotify(`Введите E-Mail пользователю!`)
    }
    if (createLastname == '' || createLastname == null) {
      return errorNotify(`Введите фамилию пользователю!`)
    }
    if (createFirstname == '' || createFirstname == null) {
      return errorNotify(`Введите имя пользователю!`)
    }
    if (createPhone == '' || createPhone == null) {
      return errorNotify(`Введите телефон пользователю!`)
    }
    if (createPassword1 == '' || createPassword1 == null || createPassword2 == '' || createPassword2 == null) {
      return errorNotify(`Введите пароль пользователю!`)
    }
    if (createPassword1 != createPassword2) {
      return errorNotify(`Пароли не совпадают!`)
    }
    const formData = new FormData()
    formData.append('email', createEmail)
    formData.append('firstname', createFirstname)
    formData.append('lastname', createLastname)
    formData.append('surname', createSurname)
    formData.append('phone', createPhone)
    formData.append('address', '')
    formData.append('countryid', createCountry)
    formData.append('cityid', createCity)
    formData.append('role', createRole)
    formData.append('type', createUserType)
    formData.append('description', createDesc)
    formData.append('verify', createVerify)
    formData.append('vk', createVK)
    formData.append('tg', createTg)
    formData.append('wp', createWp)
    formData.append('img', file)
    formData.append('password', createPassword1)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/u/create', formData)
    .then(res => {
      addLog(`Создал пользователя ${createEmail}`);
      successNotify(`Пользователь добавлен!`);
      handleCloseModalCreate();
      getUsers();
      getRoles();
      setCreateEmail('');
      setCreateDesc('');
      setCreateVK('');
      setCreateFirstname('');
      setCreateLastname('');
      setCreateSurname('');
      setCreatePassword1('');
      setCreatePassword2('');
      setCreateAddress('');
      setCreateCountry('');
      setCreateCity('');
      setCreatePhone('');
      setCreateTg('');
      setCreateWp('');
      setCreateVerify(false);
      setCreateRole(0);
      setCreateUserType('');
      setFile(null);
      setImgTitle('Выбрать файл...');
    })
    .catch(err => errorNotify(err.response.data.message));
  }

  async function changePassword(auid, uid, uemail) {
    if (newPassword1 == '' || newPassword1 == null || newPassword2 == '' || newPassword2 == null) {
      return errorNotify(`Заполните поля паролей пользователя!`)
    }
    if (newPassword1 != newPassword2) {
      return errorNotify(`Пароли не совпадают!`)
    }
    const formData = new FormData()
    formData.append('auid', auid)
    formData.append('uid', uid)
    formData.append('newpassword', newPassword1)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/u/changepassword', formData)
    .then(res => {
      addLog(`Изменил пароль пользователю ${uemail}`);
      successNotify(`Пользователь ${selectedUser} обновлен!`);
      getUsers();
      handleCloseModalPassword();
    })
    .catch(err => errorNotify(err.response.data.message));
  }

  async function deleteUser(auid, uid, uemail) {
    const formData = new FormData()
    formData.append('auid', auid)
    formData.append('uid', uid)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/u/delete', formData)
    .then(res => {
      addLog(`Удалил пользователя ${uemail}`);
      successNotify(`Пользователь удален!`);
      getUsers();
      handleCloseModalDelete();
    })
    .catch(err => errorNotify(err.response.data.message));
  }

  async function disableAllRoles(auid, uid, uemail) {
    const formData = new FormData()
    formData.append('auid', auid)
    formData.append('uid', uid)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/u/disableallroles', formData)
    .then(res => {
      addLog(`Снял все роли с пользователя ${uemail}`);
      successNotify(`Права с пользователя сняты!`);
      getUsers();
      getRoles();
    })
    .catch(err => errorNotify(err.response.data.message));
  }

  async function Uactivate(auid, uid, uemail) {
    const formData = new FormData()
    formData.append('auid', auid)
    formData.append('uid', uid)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/u/uactivate', formData)
    .then(res => {
      addLog(`Потвердил почтовый адрес пользователя ${uemail}`);
      successNotify(`Почтовый адрес подтвержден!`);
      getUsers();
      getRoles();
    })
    .catch(err => errorNotify(err.response.data.message));
  }

  async function changeRole(auid, uid, urole, uemail) {
    const formData = new FormData()
    formData.append('auid', auid)
    formData.append('uid', uid)
    formData.append('urole', urole)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/u/changerole', formData)
    .then(res => {
      addLog(`Изменил права пользователю ${uemail}`);
      successNotify(`Права пользователя обновлены!`);
      getRoles();
    })
    .catch(err => errorNotify(err.response.data.message));
  }

  async function updateProfileCity(auid, uid, uemail) {
    const formData = new FormData()
    formData.append('auid', auid)
    formData.append('uid', uid)
    formData.append('countryid', updateCountry)
    formData.append('cityid', updateCity)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/u/aupdatecity', formData)
    .then(res => {
      addLog(`Изменил страну и город пользователю ${uemail}`);
      successNotify(`Страна и город пользователя изменены!`);
      handleCloseModalUpdateCity('');
      getUsers();
      getRoles();
    })
    .catch(err => errorNotify(err.response.data.message));
  }

  async function updateRoles(auid, uid, uemail) {
    const formData = new FormData()
    if(updateUserType === '') {
      setUpdateUserType(0);
    }
    formData.append('auid', auid)
    formData.append('uid', uid)
    formData.append('role', updateUserRole)
    formData.append('roleType', updateUserType)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/u/aupdaterole', formData)
    .then(res => {
      addLog(`Изменил роль пользователю ${uemail}`);
      successNotify(`Роль пользователя изменена!`);
      handleCloseModalUpdateRoles('');
      getUsers();
      getRoles();
    })
    .catch(err => errorNotify(err.response.data.message));
  }

  async function successModerate(uid, uemail) {
    const formData = new FormData()
    formData.append('uid', uid)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/m/confirm', formData)
    .then(res => {
      addLog(`Произвел положительную оценку по модерации аккаунту ${uemail}`);
      successNotify(`Аккаунт пользователя подтвержден!`);
      getUsers();
      getModer();
      getRoles();
    })
    .catch(err => errorNotify(err.response.data.message));
  }

  async function toggleModerate(uemail) {
    const formData = new FormData()
    formData.append('email', uemail)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/m/moderate', formData)
    .then(res => {
      addLog(`Произвел ручную модерацию аккаунту ${uemail}`);
      successNotify(`Аккаунт пользователя подтвержден!`);
      getUsers();
      getModer();
      getRoles();
    })
    .catch(err => errorNotify(err.response.data.message));
  }

  async function cancelModerate(uid, uemail) {
    const formData = new FormData()
    formData.append('uid', uid)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/m/cancel', formData)
    .then(res => {
      addLog(`Произвел отрицательную оценку по модерации аккаунту ${uemail}`);
      successNotify(`Модерация аккаунта отменена!`);
      getUsers();
      getModer();
      getRoles();
    })
    .catch(err => errorNotify(err.response.data.message));
  }

    return (
      <div className="pt-2">
        <Modal
          show={showModalUpdateRoles}
          onHide={handleCloseModalUpdateRoles}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Изменение роли</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Для изменения страны и города у пользователя <strong>{selectedUser}</strong> выберите слева необходимую страну, а справа город.

            <InputGroup className="mt-2" >
            <Form.Select 
              aria-label="Default select example"
              defaultValue={updateUserRole}
              onChange={e => setUpdateUserRole(e.target.value)}
            >
              <option value='' disabled>Выберите роль</option>
              <option value={0}>Пользователь</option>
              <option value={1}>Практик</option>
              <option value={2}>Мастер</option>
              <option value={3}>Персонал</option>
            </Form.Select>
            {updateUserRole == 1 ?
            <Form.Select 
              aria-label="Default select example"
              defaultValue={updateUserType}
              onChange={e => setUpdateUserType(e.target.value)}
            >
              <option value='' disabled>Выберите тип</option>
              <option value={0}>Практик «Сияющие руки»</option>
              <option value={1}>Практик «Соединения миров»</option>
              <option value={2}>Все разделы</option>
            </Form.Select>
            :
            ``}
            </InputGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" className="outline-btn-bl" onClick={handleCloseModalUpdateRoles}>
              <i className='bx bx-layer-minus bx-fw' ></i> Отмена
            </Button>
            <Button variant="primary" className="btn-bl" onClick={() => updateRoles(store.user.id, selectedUserID, selectedUser)} >
              <i className='bx bx-check bx-fw'></i> Сменить роль
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showModalUpdateCity}
          onHide={handleCloseModalUpdateCity}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Изменение города</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Для изменения страны и города у пользователя <strong>{selectedUser}</strong> выберите слева необходимую страну, а справа город.

            <InputGroup className="mt-2" >
              <Form.Select 
                aria-label="Default select example"
                defaultValue={updateCountry}
                onChange={e => setUpdateCountry(e.target.value)}
              >
              <option value='' disabled>Выберите страну</option>
                {country.map((country) => {
                  return (
                    <option key={country._id} value={country._id}>{country.name}</option>
                  )
                })}
              </Form.Select>
              <Form.Select 
                aria-label="Default select example"
                defaultValue={updateCity}
                onChange={e => setUpdateCity(e.target.value)}
              >
              <option value='' disabled>Выберите город</option>
              {city.map((city) => {
                return (
                  updateCountry === city.cid ?
                    <option key={city._id} value={city._id}>{city.name}</option>
                  :
                  ``
                )
              })}
              </Form.Select>
            </InputGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" className="outline-btn-bl" onClick={handleCloseModalUpdateCity}>
              <i className='bx bx-layer-minus bx-fw' ></i> Отмена
            </Button>
            <Button variant="primary" className="btn-bl" onClick={() => updateProfileCity(store.user.id, selectedUserID, selectedUser)} >
              <i className='bx bx-check bx-fw'></i> Сменить город
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showModalCreate}
          onHide={handleCloseModalCreate}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Создание пользователя</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Для создания пользователя введите во все поля данные. Пароль должен состоять минимум из 6 символов.

            <InputGroup className="mt-2" >
              <FormControl
                placeholder="Введите E-Mail"
                aria-label="Введите E-Mail"
                aria-describedby="basic-addon2"
                value={createEmail.trim()}
                onChange={e => setCreateEmail(e.target.value)}
              />
              <FormControl
                placeholder="Введите фамилию"
                aria-label="Введите фамилию"
                aria-describedby="basic-addon2"
                value={createLastname}
                onChange={e => setCreateLastname(e.target.value)}
              />
            </InputGroup>

            <InputGroup className="mt-2" >
              <FormControl
                placeholder="Введите имя"
                aria-label="Введите имя"
                aria-describedby="basic-addon2"
                value={createFirstname}
                onChange={e => setCreateFirstname(e.target.value)}
              />
              <FormControl
                placeholder="Введите отчество"
                aria-label="Введите отчество"
                aria-describedby="basic-addon2"
                value={createSurname}
                onChange={e => setCreateSurname(e.target.value)}
              />
            </InputGroup>

            <InputGroup className="mt-2" >
              <FormControl
                placeholder="Введите номер телефона"
                aria-label="Введите номер телефона"
                aria-describedby="basic-addon2"
                value={createPhone.trim()}
                onChange={e => setCreatePhone(e.target.value)}
              />
              <Form.Select 
                aria-label="Default select example"
                defaultValue={createVerify}
                onChange={e => setCreateVerify(e.target.value)}
              >
                <option value={createVerify}>Не проверен</option>
                <option value={true}>Проверен</option>
              </Form.Select>
            </InputGroup>

            <InputGroup className="mt-2" >

            <Form.Select 
                aria-label="Default select example"
                defaultValue={createCountry}
                onChange={e => setCreateCountry(e.target.value)}
              >
              <option value='' disabled>Выберите страну</option>
                {country.map((country) => {
                  return (
                    <option key={country._id} value={country._id}>{country.name}</option>
                  )
                })}
              </Form.Select>
              <Form.Select 
                aria-label="Default select example"
                defaultValue={createCity}
                onChange={e => setCreateCity(e.target.value)}
              >
              <option value='' disabled>Выберите город</option>
              {city.map((city) => {
                return (
                  createCountry === city.cid ?
                    <option key={city._id} value={city._id}>{city.name}</option>
                  :
                  ``
                )
              })}
              </Form.Select>

            </InputGroup>

            <InputGroup className="mt-2" >
              <Form.Control 
                required
                type="file"
                onChange={selectFile}
              />
            </InputGroup>

            <InputGroup className="mt-2" >
              <Form.Select 
                aria-label="Default select example"
                defaultValue={createRole}
                onChange={e => setCreateRole(e.target.value)}
              >
                <option value={0}>Пользователь</option>
                <option value={1}>Практик</option>
                <option value={2}>Мастер</option>
                <option value={3}>Персонал</option>
              </Form.Select>
              {createRole == 1 ?
              <Form.Select 
                aria-label="Default select example"
                defaultValue={createUserType}
                onChange={e => setCreateUserType(e.target.value)}
              >
                <option value='' disabled>Выберите тип</option>
                <option value={0}>Практик «Сияющие руки»</option>
                <option value={1}>Практик «Соединения миров»</option>
                <option value={2}>Все разделы</option>
              </Form.Select>
              :
              ``}
            </InputGroup>

            <InputGroup className="mt-2" >
              <FormControl
                placeholder="Введите пароль"
                aria-label="Введите пароль"
                aria-describedby="basic-addon2"
                type="password"
                value={createPassword1}
                onChange={e => setCreatePassword1(e.target.value)}
              />
              <FormControl
                placeholder="Повторите пароль"
                type="password"
                aria-label="Повторите пароль"
                aria-describedby="basic-addon2"
                value={createPassword2}
                onChange={e => setCreatePassword2(e.target.value)}
              />
            </InputGroup>
            <InputGroup className="mt-2" >
              <FormControl
                placeholder="Вконтакте"
                aria-label="Вконтакте"
                aria-describedby="basic-addon2"
                value={createVK}
                onChange={e => setCreateVK(e.target.value)}
              />
              <FormControl
                placeholder="Telegram"
                aria-label="Telegram"
                aria-describedby="basic-addon2"
                value={createTg}
                onChange={e => setCreateTg(e.target.value)}
              />
              <FormControl
                placeholder="WhatsApp"
                aria-label="WhatsApp"
                aria-describedby="basic-addon2"
                value={createWp}
                onChange={e => setCreateWp(e.target.value)}
              />
            </InputGroup>
            <InputGroup className="mt-2" >
              <Form.Control
                  required
                  as="textarea"
                  style={{ height: '200px' }}
                  value={createDesc}
                  placeholder="Описание пользователя"
                  onChange={e => setCreateDesc(e.target.value)}
              />
            </InputGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" className="outline-btn-bl" onClick={handleCloseModalCreate}>
              <i className='bx bx-layer-minus bx-fw' ></i> Отмена
            </Button>
            <Button variant="primary" className="btn-bl" onClick={() => createUser()} >
              <i className='bx bx-check bx-fw'></i> Создать пользователя
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showModalPassword}
          onHide={handleCloseModalPassword}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Изменение пароля</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Для изменения пароля у пользователя <strong>{selectedUser}</strong> введите пароль и повторите его в поля ниже. Пароль должен быть не менее 6 символов.

            <InputGroup className="mt-2" >
              <FormControl
                placeholder="Введите пароль"
                aria-label="Введите пароль"
                aria-describedby="basic-addon2"
                type="password"
                value={newPassword1}
                onChange={e => setNewPassword1(e.target.value)}
              />
              <FormControl
                placeholder="Повторите пароль"
                type="password"
                aria-label="Повторите пароль"
                aria-describedby="basic-addon2"
                value={newPassword2}
                onChange={e => setNewPassword2(e.target.value)}
              />
            </InputGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" className="outline-btn-bl" onClick={handleCloseModalPassword}>
              <i className='bx bx-layer-minus bx-fw' ></i> Отмена
            </Button>
            <Button variant="primary" className="btn-bl" onClick={() => changePassword(store.user.id, selectedUserID, selectedUser)} >
              <i className='bx bx-check bx-fw'></i> Сменить пароль
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showModalDelete}
          onHide={handleCloseModalDelete}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Удаление пользователя</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Вы действительно хотите удалить пользователя <strong>{selectedUser}</strong>? После удаления данное действие отменить будет невозможно!
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" className="outline-btn-bl" onClick={handleCloseModalDelete}>
              <i className='bx bx-layer-minus bx-fw' ></i> Отмена
            </Button>
            <Button variant="primary" className="btn-bl" onClick={() => deleteUser(store.user.id, selectedUserID, selectedUser)} >
              <i className='bx bx-check bx-fw'></i> Удалить
            </Button>
          </Modal.Footer>
        </Modal>

        <Breadcrumb>
          <Link to="/" className="breadcrumb-item">
            Главная
          </Link>
          <Breadcrumb.Item active>Пользователи</Breadcrumb.Item>
        </Breadcrumb>
        <div className="shadow-sm p-4 bg-white rounded">
          <h5 className="pb-2">Пользователи и права</h5>
          <Tab.Container id="list-group-tabs-example" activeKey={activeKey} onSelect={eventKey => setActiveKey(eventKey)}>
            <Row>
              <Col sm={4}>
                <ListGroup className="bl-list-group">
                  {users.map((item) => {
                    return (
                      <ListGroup.Item key={item._id} action href={`#` + item._id}>
                      <i className='bx bx-user bx-fw' ></i> {item.email} | {item.lastName + ' ' + item.firstName} {item._id == defaultUser ? <i className='bx bx-crown bx-fw text-warning' ></i> : null}                           
                        {moder.map((moder) => {
                          return (
                            item._id === moder.uid ?
                              <i key={moder._id} className='bx bx-fw bx-error text-warning bx-flashing' ></i>
                            :
                            null
                          )
                        })}
                        <div className="position-absolute d-inline end-0 px-2"><i onClick={() => handleShowModalDelete(item._id, item.email)} className='bx bx-trash bx-fw' ></i></div>
                      </ListGroup.Item>
                    )
                  })}
                  <InputGroup className="mt-2 mb-2">
                    <Button  variant="outline-primary" className="outline-btn-bl" id="button-addon2" onClick={() => handleShowModalCreate()}>
                      <i className='bx bx-fw bx-user-plus' ></i> Создать пользователя
                    </Button>
                  </InputGroup>
                </ListGroup>
              </Col>
              <Col sm={8}>
                <Tab.Content>
                  {users.map((users) => {
                    return (
                      <Tab.Pane key={users._id} eventKey={`#` + users._id}>

                        <Card >
                        <div className="d-flex justify-content-center">
                          <img className="mt-2 rounded-circle" style={{ width: '150px', height: '150px', objectFit: 'cover' }} src={IMG_URL + users.img} />
                        </div>
                          <Card.Body>
                            <Card.Title className="text-center">{users.firstName} {users.surName} {users.lastName}</Card.Title>
                            <Card.Text className="text-center">
                            {users.email}
                            </Card.Text>
                          </Card.Body>
                          <ListGroup className="list-group-flush">

                          {moder.map((moder) => {
                            return (
                              users._id === moder.uid ?
                              <ListGroupItem key={moder._id}>
                                <InputGroup className="d-flex justify-content-center">
                                  <span className="mt-1"><i className='bx bx-fw bx-error  text-warning' ></i> Пользователь запрашивает подтверждение модерации</span>
                                </InputGroup>
                                <InputGroup className="d-flex justify-content-center mt-2">
                                  <Button onClick={() => successModerate(moder.uid, users.email)} variant="outline-primary" className="outline-btn-bl" id="button-addon2">
                                    <i className='bx bx-fw bxs-user-check' ></i> Подтвердить модерацию
                                  </Button>
                                  <Button onClick={() => cancelModerate(moder.uid, users.email)} variant="outline-primary" className="outline-btn-bl" id="button-addon2">
                                    <i className='bx bx-fw bxs-user-x' ></i> Отклонить модерацию
                                  </Button>
                                </InputGroup>
                              </ListGroupItem>
                              :
                              null
                            )
                          })}

                          <ListGroupItem>
                            <InputGroup>
                              <FormControl
                                placeholder="Введите E-Mail пользователя"
                                aria-label="Введите E-Mail пользователя"
                                aria-describedby="basic-addon2"
                                value={newEmail}
                                onChange={e => setNewEmail(e.target.value)}
                              />
                              <Button onClick={() => changeEmail(store.user.id, users._id, users.email)} variant="outline-primary" className="outline-btn-bl" id="button-addon2">
                                <i className='bx bx-fw bx-user-plus' ></i> Изменить E-Mail
                              </Button>
                              </InputGroup>
                            </ListGroupItem>


                            <ListGroupItem>
                            <InputGroup>
                              <FormControl
                                placeholder={`Фамилия: ` + users.lastName}
                                aria-label={`Фамилия: ` + users.lastName}
                                aria-describedby="basic-addon2"
                                value={updateLastName}
                                onChange={e => setUpdateLastName(e.target.value)}
                              />
                              <FormControl
                                placeholder={`Имя: ` + users.firstName}
                                aria-label={`Имя: ` + users.firstName}
                                aria-describedby="basic-addon2"
                                value={updateFirstName}
                                onChange={e => setUpdateFirstName(e.target.value)}
                              />
                              </InputGroup>
                            </ListGroupItem>

                            <ListGroupItem>
                            <InputGroup>
                              <FormControl
                                placeholder={`Отчество: ` + users.surName}
                                aria-label={`Отчество: ` + users.surName}
                                aria-describedby="basic-addon2"
                                value={updateSurName}
                                onChange={e => setUpdateSurName(e.target.value)}
                              />
                              <FormControl
                                placeholder={`Номер телефона: ` + users.phone}
                                aria-label={`Номер телефона: ` + users.phone}
                                aria-describedby="basic-addon2"
                                value={updatePhone}
                                onChange={e => setUpdatePhone(e.target.value)}
                              />
                              </InputGroup>
                            </ListGroupItem>


                            <ListGroupItem>
                            <InputGroup>
                              <FormControl
                                placeholder={`Вконтакте: ` + users.vk}
                                aria-label={`Вконтакте: ` + users.vk}
                                aria-describedby="basic-addon2"
                                value={updateVk}
                                onChange={e => setUpdateVk(e.target.value)}
                              />
                              <FormControl
                                placeholder={`Telegram: ` + users.tg}
                                aria-label={`Telegram: ` + users.tg}
                                aria-describedby="basic-addon2"
                                value={updateTg}
                                onChange={e => setUpdateTg(e.target.value)}
                              />
                              <FormControl
                                placeholder={`WhatsApp: ` + users.wp}
                                aria-label={`WhatsApp: ` + users.wp}
                                aria-describedby="basic-addon2"
                                value={updateWp}
                                onChange={e => setUpdateWp(e.target.value)}
                              />
                              </InputGroup>
                            </ListGroupItem>



                            <ListGroupItem>
                            <InputGroup>
                            <FormControl
                              placeholder="Роль"
                              aria-label="Роль"
                              disabled
                              aria-describedby="basic-addon2"
                              value={users.role === 0 ? `Пользователь` : users.role === 1 ? `Практик` : users.role === 2 ? `Мастер` : users.role === 3 ? `Персонал` : ``}
                            />
                              {users.role == 1 ?
                              <FormControl
                                placeholder="Тип"
                                aria-label="Тип"
                                disabled
                                aria-describedby="basic-addon2"
                                value={users.roleType === 0 ? `Практик «Сияющие руки»` : users.roleType === 1 ? `Практик «Соединения миров»` : users.roleType === 2 ? `Все разделы` : ``}
                              />
                              :
                              ``}
                              </InputGroup>
                            </ListGroupItem>

                            <ListGroupItem>
                            <InputGroup>
                            {country.map((country) => {
                                return (
                                  country._id === users.countryid ?
                                  <FormControl
                                  key={country._id}
                                  placeholder="Страна"
                                  aria-label="Страна"
                                  disabled
                                  aria-describedby="basic-addon2"
                                  value={`Страна: ` + country.name}
                                />
                                :
                                ``
                                )
                              })}
                              {city.map((city) => {
                                return (
                                  city._id === users.cityid ?
                                  <FormControl
                                  key={city._id}
                                  placeholder="Город"
                                  aria-label="Город"
                                  disabled
                                  aria-describedby="basic-addon2"
                                  value={`Город: ` + city.name}
                                />
                                :
                                ``
                                )
                              })}
                              </InputGroup>
                            </ListGroupItem>

                            <ListGroupItem>
                            <InputGroup>
                            <Form.Control
                                required
                                as="textarea"
                                style={{ height: '200px' }}
                                value={updateDescription}
                                placeholder={`Описание: ` + users.description}
                                aria-label={`Описание: ` + users.description}
                                onChange={e => setUpdateDescription(e.target.value)}
                            />
                            </InputGroup>
                            </ListGroupItem>

                            <ListGroupItem>


                              <ButtonToolbar aria-label="Toolbar with button groups" className="d-flex justify-content-center">
                                <ButtonGroup className="me-2" aria-label="First group">
                                <Button onClick={() => updateChanges(users._id, users.role, users.lastName, users.firstName, users.surName, users.phone, users.vk, users.tg, users.wp, users.description)} variant="outline-primary" className="outline-btn-bl" id="button-addon2">
                                      <i className='bx bx-fw bx-revision' ></i> Подтвердить изменения
                                    </Button>
                                </ButtonGroup>
                                {
                                  users.isActivated === false ?
                                  <ButtonGroup className="me-2" aria-label="First group">
                                    <Button onClick={() => Uactivate(users._id, users.email)} variant="outline-primary" className="outline-btn-bl" id="button-addon2">
                                      <i className='bx bx-fw bx-envelope' ></i> Подтвердить E-Mail
                                    </Button>
                                  </ButtonGroup>
                                  :
                                  null
                                }
                                <ButtonGroup className="me-2" aria-label="First group">
                                  <Button onClick={() => handleShowModalUpdateCity(users._id, users.email)} variant="outline-primary" className="outline-btn-bl" id="button-addon2">
                                    <i className='bx bx-fw bx-world' ></i> Сменить город
                                  </Button>
                                </ButtonGroup>
                                <ButtonGroup className="me-2" aria-label="First group">
                                <Button onClick={() => handleShowModalUpdateRoles(users._id, users.email)} variant="outline-primary" className="outline-btn-bl" id="button-addon2">
                                      <i className='bx bx-fw bx-crown' ></i> Сменить роль
                                    </Button>
                                </ButtonGroup>
                                <ButtonGroup className="me-2" aria-label="First group">
                                <Button onClick={() => handleShowModalPassword(users._id, users.email)} variant="outline-primary" className="outline-btn-bl" id="button-addon2">
                                      <i className='bx bx-fw bx-lock-open-alt' ></i> Изменить пароль
                                    </Button>
                                </ButtonGroup>
                                <ButtonGroup className="me-2" aria-label="Second group">
                                <Button variant="outline-primary" className="outline-btn-bl" id="button-addon2" onClick={() => disableAllRoles(store.user.id, users._id, users.email)}>
                                      <i className='bx bx-fw bx-shield-alt-2' ></i> Снять все права
                                    </Button>
                                </ButtonGroup>
                                <ButtonGroup className="me-2" aria-label="Third group">
                                <Button variant="outline-primary" className="outline-btn-bl" id="button-addon2" onClick={() => handleShowModalDelete(users._id, users.email)}>
                                      <i className='bx bx-fw bx-trash' ></i> Удалить пользователя
                                    </Button>
                                </ButtonGroup>

                                <ButtonGroup className="me-2" aria-label="Third group">
                                <Button variant="outline-primary" className="outline-btn-bl" id="button-addon2" onClick={() => toggleModerate(users.email)}>
                                      <i className='bx bx-fw bx-check' ></i> Провести модерацию
                                    </Button>
                                </ButtonGroup>
                              </ButtonToolbar>


                            </ListGroupItem>
                            <ListGroupItem>
                              <h5>Права пользователя</h5>
                              {roles.map((roles) => {
                                if (users._id === roles.uid) {
                                return (
                                  <div key={roles._id} className="row p-4">
                                    <Form.Check
                                      defaultChecked={roles.viewNews}
                                      className="h6 col-xxl-4 col-xl-6 col-12"
                                      type="switch"
                                      id="custom-switch"
                                      label="Просмотр раздела новостей"
                                      onClick={() => changeRole(store.user.id, users._id, 'viewNews', users.email)}
                                    />
                                    <Form.Check
                                      defaultChecked={roles.createNews}
                                      className="h6 col-xxl-4 col-xl-6 col-12"
                                      type="switch"
                                      id="custom-switch"
                                      label="Создание новостей"
                                      onClick={() => changeRole(store.user.id, users._id, 'createNews', users.email)}
                                    />
                                    <Form.Check
                                      defaultChecked={roles.publicNews}
                                      className="h6 col-xxl-4 col-xl-6 col-12"
                                      type="switch"
                                      id="custom-switch"
                                      label="Публикация новостей"
                                      onClick={() => changeRole(store.user.id, users._id, 'publicNews', users.email)}
                                    />
                                    <Form.Check
                                      defaultChecked={roles.deleteNews}
                                      className="h6 col-xxl-4 col-xl-6 col-12"
                                      type="switch"
                                      id="custom-switch"
                                      label="Удаление новостей"
                                      onClick={() => changeRole(store.user.id, users._id, 'deleteNews', users.email)}
                                    />
                                    <Form.Check
                                      defaultChecked={roles.viewPages}
                                      className="h6 col-xxl-4 col-xl-6 col-12"
                                      type="switch"
                                      id="custom-switch"
                                      label="Просмотр страниц"
                                      onClick={() => changeRole(store.user.id, users._id, 'viewPages', users.email)}
                                    />
                                    <Form.Check
                                      defaultChecked={roles.createPages}
                                      className="h6 col-xxl-4 col-xl-6 col-12"
                                      type="switch"
                                      id="custom-switch"
                                      label="Создание страниц"
                                      onClick={() => changeRole(store.user.id, users._id, 'createPages', users.email)}
                                    />
                                    <Form.Check
                                      defaultChecked={roles.publicPages}
                                      className="h6 col-xxl-4 col-xl-6 col-12"
                                      type="switch"
                                      id="custom-switch"
                                      label="Публикация страниц"
                                      onClick={() => changeRole(store.user.id, users._id, 'publicPages', users.email)}
                                    />
                                    <Form.Check
                                      defaultChecked={roles.deletePages}
                                      className="h6 col-xxl-4 col-xl-6 col-12"
                                      type="switch"
                                      id="custom-switch"
                                      label="Удаление страниц"
                                      onClick={() => changeRole(store.user.id, users._id, 'deletePages', users.email)}
                                    />
                                    <Form.Check
                                      defaultChecked={roles.viewCity}
                                      className="h6 col-xxl-4 col-xl-6 col-12"
                                      type="switch"
                                      id="custom-switch"
                                      label="Просмотр городов"
                                      onClick={() => changeRole(store.user.id, users._id, 'viewCity', users.email)}
                                    />
                                    <Form.Check
                                      defaultChecked={roles.createCity}
                                      className="h6 col-xxl-4 col-xl-6 col-12"
                                      type="switch"
                                      id="custom-switch"
                                      label="Добавление городов"
                                      onClick={() => changeRole(store.user.id, users._id, 'createCity', users.email)}
                                    />
                                    <Form.Check
                                      defaultChecked={roles.deleteCity}
                                      className="h6 col-xxl-4 col-xl-6 col-12"
                                      type="switch"
                                      id="custom-switch"
                                      label="Удаление городов"
                                      onClick={() => changeRole(store.user.id, users._id, 'deleteCity', users.email)}
                                    />
                                    <Form.Check
                                      defaultChecked={roles.viewUsers}
                                      className="h6 col-xxl-4 col-xl-6 col-12"
                                      type="switch"
                                      id="custom-switch"
                                      label="Просмотр пользователей"
                                      onClick={() => changeRole(store.user.id, users._id, 'viewUsers', users.email)}
                                    />
                                    <Form.Check
                                      defaultChecked={roles.createUsers}
                                      className="h6 col-xxl-4 col-xl-6 col-12"
                                      type="switch"
                                      id="custom-switch"
                                      label="Создание пользователей"
                                      onClick={() => changeRole(store.user.id, users._id, 'createUsers', users.email)}
                                    />
                                    <Form.Check
                                      defaultChecked={roles.changeUsers}
                                      className="h6 col-xxl-4 col-xl-6 col-12"
                                      type="switch"
                                      id="custom-switch"
                                      label="Редактирование пользователей"
                                      onClick={() => changeRole(store.user.id, users._id, 'changeUsers', users.email)}
                                    />
                                    <Form.Check
                                      defaultChecked={roles.permissionUsers}
                                      className="h6 col-xxl-4 col-xl-6 col-12"
                                      type="switch"
                                      id="custom-switch"
                                      label="Редактирование прав пользователей"
                                      onClick={() => changeRole(store.user.id, users._id, 'permissionUsers', users.email)}
                                    />
                                    <Form.Check
                                      defaultChecked={roles.deleteUsers}
                                      className="h6 col-xxl-4 col-xl-6 col-12"
                                      type="switch"
                                      id="custom-switch"
                                      label="Удаление пользователей"
                                      onClick={() => changeRole(store.user.id, users._id, 'deleteUsers', users.email)}
                                    />
                                    <Form.Check
                                      defaultChecked={roles.viewLogs}
                                      className="h6 col-xxl-4 col-xl-6 col-12"
                                      type="switch"
                                      id="custom-switch"
                                      label="Просмотр логов"
                                      onClick={() => changeRole(store.user.id, users._id, 'viewLogs', users.email)}
                                    />
                                  </div>
                                )
                                }
                              })}
                            </ListGroupItem>
                          </ListGroup>
                        </Card>

                      </Tab.Pane>
                    )
                  })}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
    );
};

export default UsersPage;