import React, {useContext, useState, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import {Table} from 'react-bootstrap';
import 'boxicons/css/boxicons.css';
import 'boxicons';
import {Breadcrumb} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import axios from 'axios';
import Pagination from 'react-bootstrap/Pagination';
import {Modal, Button} from 'react-bootstrap';
import ReactPaginate from "react-paginate";
import '../../style.css';
import successNotify from '../success-notify';
import errorNotify from '../error-notify';
import {Context} from '../../index';
import { API_URL } from '../../http';


const PaymentsPage = () => {
  const [items, setItems] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const {store} = useContext(Context);
  const [basket, setBasket] = useState([]);
  const [details, setDetails] = useState([]);
  const [status, setStatus] = useState('');
  const [amount, setAmount] = useState('');
  const [steps, setSteps] = useState([]);
  const [type, setType] = useState(0);
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [surName, setSurName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  let limit = 10;

  const [showModalNews, setShowModalNews] = useState(false);
  const [showModalDetails, setShowModalDetails] = useState(false);

  const handleCloseModalDetails = () => setShowModalDetails(false);
  const handleShowModalDetails = (id, iLastName, iFirstName, iSurName, iEmail, iPhone) => {
    getDetails(id);
    setLastName(iLastName);
    setFirstName(iFirstName);
    setSurName(iSurName);
    setEmail(iEmail);
    setPhone(iPhone);
    setShowModalDetails(true);
  }

  const handleCloseModalNews = () => setShowModalNews(false);
  const handleShowModalNews = (id, status, amount, type, iLastName, iFirstName, iSurName, iEmail, iPhone) => {
    getBasket(id, status, amount, type);
    getSteps(id);
    setLastName(iLastName);
    setFirstName(iFirstName);
    setSurName(iSurName);
    setEmail(iEmail);
    setPhone(iPhone);
    setShowModalNews(true);
  }

  async function getBasket(id, status, amount, type) {
    const formData = new FormData()
    formData.append('orderId', id)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/purchase/getpayments/basket', formData)
    .then(res => {
      setBasket(res.data);
      setAmount(amount);
      setStatus(status);
      setType(type);
    })
    .catch(err => errorNotify(err.response.data.message));
  }
  async function getSteps(id) {
    const formData = new FormData()
    formData.append('orderId', id)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/purchase/getpayments/steps', formData)
    .then(res => {
      setSteps(res.data);
    })
    .catch(err => errorNotify(err.response.data.message));
  }
  async function getDetails(id) {
    const formData = new FormData()
    formData.append('orderId', id)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/purchase/getpayments/details', formData)
    .then(res => {
      setDetails(res.data);
    })
    .catch(err => errorNotify(err.response.data.message));
  }

  const getNews = async () => {
    const res = await fetch(
      API_URL + `/purchase/getallpayments?page=1&limit=${limit}`, {
        headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
       });
    const data = await res.json();
    const total = res.headers.get("x-total-count");
    setpageCount(Math.ceil(total / limit));
    setItems(data);
  };

  useEffect(() => {
    getNews();
  }, [limit]);

  const fetchNews = async (currentPage) => {
    const res = await fetch(
      API_URL + `/purchase/getallpayments?page=${currentPage}&limit=${limit}`, {
        headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
       });
    const data = await res.json();
    return data;
  };

  const handlePageClick = async (data) => {

    let currentPage = data.selected + 1;

    const commentsFormServer = await fetchNews(currentPage);

    setItems(commentsFormServer);
  };


    return (
      <div className="pt-2">
        <Modal
          show={showModalNews}
          onHide={handleCloseModalNews}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Детали покупки</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <strong>Идентификатор покупки:</strong><br />{basket?.purchase_id}<br /><br />
            <strong>Наименование товара:</strong><br />{basket?.nameItem}<br /><br />
            <strong>Стоимость товара:</strong><br /><strong>{basket?.amountItem?.slice(0, -2)}</strong> ₽ <br />
            {
              type === 1 ?
              <>
              <hr />
              <strong>Логистическая компания:</strong><br />{basket?.nameDelivery}<br /><br />
              <strong>Стоимость логистики:</strong><br />{basket?.amountDelivery?.slice(0, -2)} ₽<br /><br /><br />
              <hr />
              </>
              :
              <br />
            }
            {
              type === 2 ?
              <>
              <hr />
              <strong>Клиент:</strong><br />{lastName + ' ' + firstName + ' ' + surName}<br /><br />
              <strong>E-Mail:</strong><br />{email}<br /><br />
              <strong>Номер телефона:</strong><br />{phone}<br /><br />
              <hr />
              </>
              :
              <br />
            }
            <strong>ИТОГО: {amount}</strong> ₽
            {
              type === 1 ?
              <>
              <br />
              <hr />
              <strong>Логистические данные</strong>
              {steps.map((step) => {
              return (
                <div key={step.key}>
                  <hr/>
                  {step.stepDescription} {step.addInfo}
                  <hr/>
                </div>
                )
              })}
              </>
              :
              null

            }
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" className="outline-btn-bl" onClick={handleCloseModalNews}>
              <i className='bx bx-layer-minus bx-fw' ></i> Понятно
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showModalDetails}
          onHide={handleCloseModalDetails}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Детали заказа</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <strong>Идентификатор покупки:</strong><br />{details?.purchase_id}<br /><br />
            <strong>Страна:</strong><br />{details?.country}<br /><br />
            <strong>Регион/область:</strong><br />{details?.state}<br /><br />
            <strong>Город:</strong><br />{details?.city}<br /><br />
            <strong>Адрес:</strong><br />{details?.address}<br /><br />
            <strong>Индекс:</strong><br />{details?.indexPurchase}<br /><br />
            <strong>Логистика:</strong><br /><strong>{details?.postType === 1 ? 'Почта России' : 'СДЭК'}</strong><br /><br />
            <hr /><br />
            <strong>Фамилия:</strong><br />{lastName}<br /><br />
            <strong>Имя:</strong><br />{firstName}<br /><br />
            <strong>Отчество:</strong><br />{surName}<br /><br />
            <strong>E-Mail:</strong><br />{email}<br /><br />
            <strong>Номер телефона:</strong><br />{phone}<br /><br />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" className="outline-btn-bl" onClick={handleCloseModalDetails}>
              <i className='bx bx-layer-minus bx-fw' ></i> Понятно
            </Button>
          </Modal.Footer>
        </Modal>
        <Breadcrumb>
          <Link to="/" className="breadcrumb-item">
            Главная
          </Link>
          <Breadcrumb.Item active>Платежи</Breadcrumb.Item>
        </Breadcrumb>
        <div className="shadow-sm p-4 bg-white rounded">
          <h5 className="pb-2">Платежи</h5>
          <table className="admintable mb-2">
          <thead>
            <tr>
              <th>ID покупки</th>
              <td>Дата</td>
              <th>E-Mail</th>
              <th>PAN</th>
              <th>Сумма</th>
              <th>Тип платежа</th>
              <th>Действия</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item) => {
              return (
                <tr key={item._id}>
                <td data-label="ID покупки">{item._id}</td>
                <td data-label="Дата">{item.purchaseDate}</td>
                <td data-label="E-Mail">{item.email}</td>
                <td data-label="PAN">{item.pan === undefined ? 'Платеж создан' : item.pan}</td>
                <td data-label="Сумма">{item?.amount?.slice(0, -2)} ₽</td>
                <td data-label="Тип платежа">{item.purchaseType === 1 ? "Книги" : "Курсы/семинары"}</td>
                <td>
                  <div className={"inline"}>
                    <a className="ico-btn ico-visible" onClick={() => handleShowModalNews(item._id, item.purchaseStatus, item.amount.slice(0, -2), item.purchaseType, item.lastName, item.firstName, item.surName, item.email, item.phone)}>
                      <i className="bx bx-receipt bx-fw">
                      </i>
                    </a>
                    {
                    item.purchaseType === 1 ?
                    <a className="ico-btn ico-visible" onClick={() => handleShowModalDetails(item._id, item.lastName, item.firstName, item.surName, item.email, item.phone)}>
                      <i className="bx bx-package bx-fw">
                      </i>
                    </a>
                    :
                    null
                    }
                  </div>
                </td>
                </tr>
              )
            })}
            </tbody>
          </table> 
          <ReactPaginate
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              previousLabel={"Назад"}
              nextLabel={"Вперед"}
              pageCount={pageCount}
              onPageChange={handlePageClick}
              containerClassName="pagination"
              breakClassName="page-item"
              breakLabel={<div className="page-link">...</div>}
              pageClassName="page-item"
              previousClassName="page-item"
              nextClassName="page-item"
              pageLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              activeClassName="active paginate-btn-active"
          />
        </div>
      </div>
    );
};

export default PaymentsPage;