import React, {useState, useEffect, useContext} from 'react';
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import '../style.css';
import 'boxicons/css/boxicons.css';
import 'boxicons';
import {Outlet, NavLink, Link} from 'react-router-dom';
import { SITE_URL } from '../http';

const Dashboard = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);
  const {store} = useContext(Context);


  useEffect(() => {
    document.body.classList.toggle('sb-sidenav-toggled', isOpen);
  },[isOpen])

  useEffect(() => {
    var smallMenu = document.querySelector("#navbarDropdown");
    var smallMenuDropdown = document.querySelector("#navbarDropdownMenu");
    smallMenu.classList.toggle("show", isOpenMenu);
    smallMenuDropdown.classList.toggle("show", isOpenMenu);
  },[isOpenMenu])

  useEffect(() => {
    var smallMobileMenu = document.querySelector("#navbarSupportedContent");
    smallMobileMenu.classList.toggle("show", isOpenMobileMenu);
    var smallMobileMenuButton = document.querySelector("#mobileMenuButton");
    smallMobileMenuButton.classList.toggle("collapsed", isOpenMobileMenu);
  },[isOpenMobileMenu])


    return (
      <div className="d-flex" id="wrapper">
        <div className="bg-light" id="sidebar-wrapper">
        <div className="sidebar-heading bg-light h5">Bubble CMS</div>
          <div className="list-group list-group-flush">
              <div className="p-1"></div>
              <NavLink to="/" className={(navData) => navData.isActive ? "list-group-item list-group-item-action list-group-item-light p-2 px-4 btn-bordered border-0 mb-2 text-white btn-panel" : "list-group-item list-group-item-action list-group-item-light p-2 px-4 btn-bordered border-0 mb-2 bg-light btn-inactive"}><i className='bx-fw bx bx-home'></i> Главная</NavLink >
              <NavLink to="/news" className={(navData) => navData.isActive ? "list-group-item list-group-item-action list-group-item-light p-2 px-4 btn-bordered border-0 mb-2 text-white btn-panel" : "list-group-item list-group-item-action list-group-item-light p-2 px-4 btn-bordered border-0 mb-2 bg-light btn-inactive"}><i className='bx-fw bx bxs-news'></i> Новости</NavLink >
              <NavLink to="/lands" className={(navData) => navData.isActive ? "list-group-item list-group-item-action list-group-item-light p-2 px-4 btn-bordered border-0 mb-2 text-white btn-panel" : "list-group-item list-group-item-action list-group-item-light p-2 px-4 btn-bordered border-0 mb-2 bg-light btn-inactive"}><i className='bx-fw bx bx-hive'></i> Курсы</NavLink >
              <NavLink to="/payments" className={(navData) => navData.isActive ? "list-group-item list-group-item-action list-group-item-light p-2 px-4 btn-bordered border-0 mb-2 text-white btn-panel" : "list-group-item list-group-item-action list-group-item-light p-2 px-4 btn-bordered border-0 mb-2 bg-light btn-inactive"}><i className='bx-fw bx bx-store-alt'></i> Платежи</NavLink>
              <NavLink to="/pages" className={(navData) => navData.isActive ? "list-group-item list-group-item-action list-group-item-light p-2 px-4 btn-bordered border-0 mb-2 text-white btn-panel" : "list-group-item list-group-item-action list-group-item-light p-2 px-4 btn-bordered border-0 mb-2 bg-light btn-inactive"}><i className='bx-fw bx bxs-book-content'></i> Страницы</NavLink>
              <NavLink to="/module" className={(navData) => navData.isActive ? "list-group-item list-group-item-action list-group-item-light p-2 px-4 btn-bordered border-0 mb-2 text-white btn-panel" : "list-group-item list-group-item-action list-group-item-light p-2 px-4 btn-bordered border-0 mb-2 bg-light btn-inactive"}><i className='bx-fw bx bxs-package'></i> Модуль</NavLink>
              <NavLink to="/books" className={(navData) => navData.isActive ? "list-group-item list-group-item-action list-group-item-light p-2 px-4 btn-bordered border-0 mb-2 text-white btn-panel" : "list-group-item list-group-item-action list-group-item-light p-2 px-4 btn-bordered border-0 mb-2 bg-light btn-inactive"}><i className='bx-fw bx bxs-book'></i> Книги</NavLink>
              <NavLink to="/country" className={(navData) => navData.isActive ? "list-group-item list-group-item-action list-group-item-light p-2 px-4 btn-bordered border-0 mb-2 text-white btn-panel" : "list-group-item list-group-item-action list-group-item-light p-2 px-4 btn-bordered border-0 mb-2 bg-light btn-inactive"}><i className='bx-fw bx bx-world'></i> Страны</NavLink>
              <NavLink to="/settings" className={(navData) => navData.isActive ? "list-group-item list-group-item-action list-group-item-light p-2 px-4 btn-bordered border-0 mb-2 text-white btn-panel" : "list-group-item list-group-item-action list-group-item-light p-2 px-4 btn-bordered border-0 mb-2 bg-light btn-inactive"}><i className='bx-fw bx bx-cog'></i> Настройки</NavLink>
              <NavLink to="/schedule" className={(navData) => navData.isActive ? "list-group-item list-group-item-action list-group-item-light p-2 px-4 btn-bordered border-0 mb-2 text-white btn-panel" : "list-group-item list-group-item-action list-group-item-light p-2 px-4 btn-bordered border-0 mb-2 bg-light btn-inactive"}><i className='bx-fw bx bxs-calendar'></i> Расписания</NavLink>
              <NavLink to="/courses" className={(navData) => navData.isActive ? "list-group-item list-group-item-action list-group-item-light p-2 px-4 btn-bordered border-0 mb-2 text-white btn-panel" : "list-group-item list-group-item-action list-group-item-light p-2 px-4 btn-bordered border-0 mb-2 bg-light btn-inactive"}><i className='bx-fw bx bxs-notepad'></i> Список записанных</NavLink>
              <NavLink to="/registers" className={(navData) => navData.isActive ? "list-group-item list-group-item-action list-group-item-light p-2 px-4 btn-bordered border-0 mb-2 text-white btn-panel" : "list-group-item list-group-item-action list-group-item-light p-2 px-4 btn-bordered border-0 mb-2 bg-light btn-inactive"}><i className='bx-fw bx bx-user-plus'></i> Заявки на курс</NavLink>
              <NavLink to="/tickets" className={(navData) => navData.isActive ? "list-group-item list-group-item-action list-group-item-light p-2 px-4 btn-bordered border-0 mb-2 text-white btn-panel" : "list-group-item list-group-item-action list-group-item-light p-2 px-4 btn-bordered border-0 mb-2 bg-light btn-inactive"}><i className='bx-fw bx bx-help-circle'></i> Заявки</NavLink>
              <NavLink to="/users" className={(navData) => navData.isActive ? "list-group-item list-group-item-action list-group-item-light p-2 px-4 btn-bordered border-0 mb-2 text-white btn-panel" : "list-group-item list-group-item-action list-group-item-light p-2 px-4 btn-bordered border-0 mb-2 bg-light btn-inactive"}><i className='bx-fw bx bx-user'></i> Пользователи</NavLink>
              <NavLink to="/profile" className={(navData) => navData.isActive ? "list-group-item list-group-item-action list-group-item-light p-2 px-4 btn-bordered border-0 mb-2 text-white btn-panel" : "list-group-item list-group-item-action list-group-item-light p-2 px-4 btn-bordered border-0 mb-2 bg-light btn-inactive"}><i className='bx-fw bx bx-user-circle'></i> Мой профиль</NavLink>
              <NavLink to="/mailing" className={(navData) => navData.isActive ? "list-group-item list-group-item-action list-group-item-light p-2 px-4 btn-bordered border-0 mb-2 text-white btn-panel" : "list-group-item list-group-item-action list-group-item-light p-2 px-4 btn-bordered border-0 mb-2 bg-light btn-inactive"}><i className='bx-fw bx bx-mail-send'></i> Рассылки</NavLink>
              <NavLink to="/media" className={(navData) => navData.isActive ? "list-group-item list-group-item-action list-group-item-light p-2 px-4 btn-bordered border-0 mb-2 text-white btn-panel" : "list-group-item list-group-item-action list-group-item-light p-2 px-4 btn-bordered border-0 mb-2 bg-light btn-inactive"}><i className='bx-fw bx bxs-videos'></i> Медиа контент</NavLink>
              <NavLink to="/video" className={(navData) => navData.isActive ? "list-group-item list-group-item-action list-group-item-light p-2 px-4 btn-bordered border-0 mb-2 text-white btn-panel" : "list-group-item list-group-item-action list-group-item-light p-2 px-4 btn-bordered border-0 mb-2 bg-light btn-inactive"}><i className='bx-fw bx bxl-youtube'></i> Видео</NavLink>
              <NavLink to="/about" className={(navData) => navData.isActive ? "list-group-item list-group-item-action list-group-item-light p-2 px-4 btn-bordered border-0 mb-2 text-white btn-panel" : "list-group-item list-group-item-action list-group-item-light p-2 px-4 btn-bordered border-0 mb-2 bg-light btn-inactive"}><i className='bx-fw bx bx-chip'></i> О системе</NavLink>
              <NavLink to="/support" className={(navData) => navData.isActive ? "list-group-item list-group-item-action list-group-item-light p-2 px-4 btn-bordered border-0 mb-2 text-white btn-panel" : "list-group-item list-group-item-action list-group-item-light p-2 px-4 btn-bordered border-0 mb-2 bg-light btn-inactive"}><i className='bx-fw bx bx-support bx-tada'></i> Поддержка</NavLink>
              <div className="p-1"></div>
          </div>
        </div>
        <div id="page-content-wrapper" className="bg-light">
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <div className="container-fluid">
                  <box-icon onClick={()=> setIsOpen(!isOpen)} name={!isOpen ? 'chevron-left' : 'chevron-right'} size='md' className="btn-nav" border='circle'></box-icon>
                  <button onClick={()=> setIsOpenMobileMenu(!isOpenMobileMenu)} id="mobileMenuButton" className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded={!isOpenMobileMenu ? 'false' : 'true'} aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                  <div className="collapse navbar-collapse" id="navbarSupportedContent">
                      <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
                          <li className="nav-item active"><a className="nav-link" href={SITE_URL} target="_blank"><i className='bx bx-world bx-fw'></i> Перейти на сайт</a></li>
                          <li className="nav-item"><Link to="/logs" className="nav-link"><i className='bx bx-bell bx-fw' ></i> Все события</Link></li>
                          <li className="nav-item dropdown">
                              <a className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" onClick={()=> setIsOpenMenu(!isOpenMenu)} aria-haspopup={!isOpenMenu ? 'false' : 'true'} aria-expanded="false"><i className='bx bx-user-circle bx-fw' ></i> {store.user.firstName + ' ' + store.user.lastName}</a>
                              <div className="dropdown-menu dropdown-menu-end" data-bs-popper={!isOpenMenu ? '' : 'none'} id="navbarDropdownMenu" aria-labelledby="navbarDropdown">
                                  <a className="dropdown-item disabled">{store.user.email}</a>
                                  <div className="dropdown-divider"></div>
                                  <Link to="/profile" className="dropdown-item btn-profile"><i className='bx-fw bx bx-id-card' ></i>Профиль</Link>
                                  <div className="dropdown-divider"></div>
                                  <a onClick={() => store.logout()} className="dropdown-item btn-profile"><i className='bx-fw bx bx-log-out-circle'></i>Выйти</a>
                              </div>
                          </li>
                      </ul>
                  </div>
              </div>
          </nav>
          <div className="container-fluid">
            <Outlet />
          </div>
        </div>
      </div>
    );
};

export default observer(Dashboard);
