import React, {useState, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import {Breadcrumb} from 'react-bootstrap';
import 'boxicons/css/boxicons.css';
import 'boxicons';
import UserChart from '../users-chart';
import SupportChart from '../support-chart';
import { API_URL } from '../../http';




const DashboardPage = () => {

  const [logs, setLogs] = useState([]);
  const [tickets, setTickets] = useState('');
  const [pages, setPages] = useState('');
  const [news, setNews] = useState('');

  const getLogs = async () => {
    const res = await fetch(
      API_URL + `/l`, {
      headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
     });
    const data = await res.json();
    setLogs(data);
  };

  const getTickets = async () => {
    const res = await fetch(
      API_URL + `/t/all`, {
      headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
     });
    const data = await res.json();
    setTickets(data);
  };

  const getPages = async () => {
    const res = await fetch(
      API_URL + `/p/count`, {
      headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
     });
    const data = await res.json();
    setPages(data);
  };

  const getNews = async () => {
    const res = await fetch(
      API_URL + `/n/count`, {
      headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
     });
    const data = await res.json();
    setNews(data);
  };

  useEffect(() => {
    getLogs();
  }, [setLogs]);

  useEffect(() => {
    getTickets();
  }, [setTickets]);

  useEffect(() => {
    getPages();
  }, [setPages]);

  useEffect(() => {
    getNews();
  }, [setNews]);


    return (
      <div className="pt-2">
        <Breadcrumb>
          <Breadcrumb.Item active>Главная</Breadcrumb.Item>
        </Breadcrumb>
        <div className="row row-sm mb-2">
          <div className="col-md-12">
            <div className="shadow-sm p-4 bg-white rounded">
              <h5 className="pb-2">Статистика</h5>
              <div className="row d-flex justify-content-around">
                  <div className="col-xl-3 col-lg-6 mb-2 mb-md-0">
                    <div className="card ">
                      <div className="card-body">
                        <div className="d-flex justify-content-between px-2">
                          <div className="align-self-center align-middle">
                          <span className="align-middle"> <i className='display-4 text-success bx bxs-user-account' ></i></span>
                          </div>
                          <div className="text-start">
                            <h4 className="m-0 text-success p-0">50</h4>
                            <p className="m-0 p-0">Просмотров</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6 mb-2 mb-md-0">
                    <div className="card ">
                      <div className="card-body">
                        <div className="d-flex justify-content-between px-2">
                          <div className="align-self-center align-middle">
                          <span className="align-middle"> <i className='display-4 text-primary bx bx-news' ></i></span>
                          </div>
                          <div className="text-start">
                            <h4 className="m-0 text-primary p-0">{news == '' || news == null || news == undefined ? `--` : news}</h4>
                            <p className="m-0 p-0">Новостей</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6 mb-2 mb-md-0">
                    <div className="card ">
                      <div className="card-body">
                        <div className="d-flex justify-content-between px-2">
                          <div className="align-self-center align-middle">
                          <span className="align-middle"> <i className='display-4 text-danger bx bxs-book-content' ></i></span>
                          </div>
                          <div className="text-start">
                            <h4 className="m-0 text-danger p-0">{pages == '' || pages == null || pages == undefined ? `--` : pages}</h4>
                            <p className="m-0 p-0">Страниц</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6 mb-2 mb-md-0">
                    <div className="card ">
                      <div className="card-body">
                        <div className="d-flex justify-content-between px-2">
                          <div className="align-self-center align-middle">
                          <span className="align-middle"> <i className='display-4 text-warning bx bx-support' ></i></span>
                          </div>
                          <div className="text-start">
                            <h4 className="m-0 text-warning p-0">{tickets == '' || tickets == null || tickets == undefined ? `--` : tickets}</h4>
                            <p className="m-0 p-0">Тикетов</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row row-sm mb-2">
          <div className="col-xxl-5 col-xl-12 mt-2 mt-xxl-0">
            <div className="shadow-sm p-4 bg-white rounded">
                <h5 className="pb-3">С возвращением!</h5>
                <p>Добро пожаловать снова в панель управления сайтом, давайте посмотрим что изменилось с момента последнего входа?</p>
              </div>
              <div className="shadow-sm p-4 bg-white rounded mt-2">
                  <h5 className="pb-4">Заявки в поддержку</h5>
                  <div className="d-flex justify-content-center">
                    <SupportChart />
                  </div>
              </div>
            </div>
            <div className="col-xxl-7 col-xl-12">
              <div className="shadow-sm p-4 bg-white rounded">
                <h5 className="pb-3">Просмотры сайта</h5>
                <UserChart />
              </div>
            </div>
        </div>
        <div className="shadow-sm p-4 bg-white rounded">
          <h5 className="pb-2">Последние события</h5>

          <table className="admintable mb-2">
            <thead>
              <tr>
                <th>Дата и время</th>
                <th>Пользователь</th>
                <th>Событие</th>
              </tr>
            </thead>
            <tbody>
            {logs.map((logs) => {
              return (
                <tr key={logs._id}>
                  <td data-label="Дата и время">{logs.logDate}</td>
                  <td data-label="Пользователь">{logs.username}</td>
                  <td data-label="Событие">{logs.description}</td>
                </tr>
              )
            })}
            </tbody>
          </table> 
        </div>
      </div>
    );
};

export default observer(DashboardPage);
