import React, { useState, useContext, useEffect } from 'react';
import { observer } from "mobx-react-lite";
import { Breadcrumb, Tab, Row, Col, ListGroup, Form, Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'boxicons/css/boxicons.css';
import 'boxicons';
import successNotify from '../success-notify';
import errorNotify from '../error-notify';
import axios from 'axios';
import { API_URL } from '../../http';
import {Context} from '../../index';
import Table from 'react-bootstrap/Table';




const CoursesPage = () => {

  const {store} = useContext(Context);
  const [items, setItems] = useState([]);
  const [courses, setCourses] = useState([]);

  const [idEdit, setIdEdit] = useState('');
  const [titleEdit, setTitleEdit] = useState('');
  const [descEdit, setDescEdit] = useState('');

  const [inDateEdit, setInDateEdit] = useState('');
  const [leadingEdit, setLeadingEdit] = useState('');
  const [placeEdit, setPlaceEdit] = useState('');

  const [title, setTitle] = useState('');
  const [desc, setDesc] = useState('');


  const [inDate, setInDate] = useState('');
  const [leading, setLeading] = useState('');
  const [place, setPlace] = useState('');


  const createFaq = () => {
    if (title == '' || inDate == '' || leading == '' || place == '') {
      return errorNotify('Заполните все поля расписания!');
    }
    const formData = new FormData()
    formData.append('title', title)
    formData.append('inDate', inDate)
    formData.append('leading', leading)
    formData.append('place', place)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/schedule/create', formData)
    .then(res => {
      successNotify(`Расписание добавлено!`);
      addLog(`Добавил расписание №${res.data._id}`);
      getFaq();
    })
    .catch(err => {
      errorNotify(err.response.data.message);
    });
  }

  const editFaq = () => {
    if (titleEdit == '' || inDateEdit == '' || leadingEdit == '' || placeEdit == '') {
      return errorNotify('Заполните все поля расписания!');
    }
    const formData = new FormData()
    formData.append('_id', idEdit)
    formData.append('title', titleEdit)
    formData.append('inDate', inDateEdit)
    formData.append('leading', leadingEdit)
    formData.append('place', placeEdit)


    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/schedule/update', formData)
    .then(res => {
      successNotify(`Расписание обновлено!`);
      addLog(`Отредактировал расписание №${idEdit}`);
      getFaq();
    })
    .catch(err => {
      errorNotify(err.response.data.message);
    });
  }

  async function addLog(description) {
    const formData = new FormData()
    formData.append('username', store.user.email)
    formData.append('description', description)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/l/create', formData)
    .then(res => {
      console.log(res);
    })
    .catch(err => console.log(err));
  }

  async function applyInfo(_id, title, dateIn, leading, place) {
    setIdEdit(_id);
    setTitleEdit(title);
    setInDateEdit(dateIn);
    setLeadingEdit(leading);
    setPlaceEdit(place);
  }

  const getFaq = async () => {
    const res = await fetch(
      API_URL + `/schedule`
    );
    const data = await res.json();
    setItems(data);
  };


  const getCourses = async () => {
    const res = await fetch(
      API_URL + `/members`, {
        headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
       });
    const data = await res.json();
    setCourses(data);
  };

  useEffect(() => {
    getCourses();
    getFaq();
  }, [setItems]);

    return (
      <div className="pt-2">
        
      <Breadcrumb>
        <Link className="breadcrumb-item" to="/">
          Главная
        </Link>
        <Breadcrumb.Item active>Расписание</Breadcrumb.Item>
      </Breadcrumb>
        <div className="shadow-sm p-4 bg-white rounded">
          <h5 className="pb-2">Расписание</h5>
          <Tab.Container defaultActiveKey="#link1">
            <Row>
              <Col sm={4}>
                <ListGroup className="bl-list-group">
                  {items.map((item) => {
                    return (
                    <ListGroup.Item key={item._id} action href={`#` + item._id} onClick={() => applyInfo(item._id, item.title, item.inDate, item.leading, item.place)}>
                      <i className='bx bxs-copy-alt bx-fw' ></i> {item.title}
                    </ListGroup.Item>
                    )
                  })}
                </ListGroup>
              </Col>
              <Col sm={8}>
                <Tab.Content>
                  {items.map((item) => {
                    return (
                    <Tab.Pane key={item._id} eventKey={`#` + item._id}>
                      <ListGroup>
                        <ListGroup.Item variant={`dark`}>
                          <div className="d-flex justify-content-between">
                            <b>Расписание: </b>
                            {item._id}
                          </div>
                        </ListGroup.Item>
                        <ListGroup.Item variant={`dark`}>
                          <div className="d-flex justify-content-between">
                            <b>Название курса</b>
                            {item.title}
                          </div>
                        </ListGroup.Item>
                        <ListGroup.Item variant={`dark`}>
                          <div className="d-flex justify-content-between">
                            <b>Дата курса</b>
                            {item.inDate}
                          </div>
                        </ListGroup.Item>
                        <ListGroup.Item variant={`dark`}>
                          <div className="d-flex justify-content-between">
                            <b>Ведущий</b>
                            {item.leading}
                          </div>
                        </ListGroup.Item>
                        <ListGroup.Item variant={`dark`}>
                          <div className="d-flex justify-content-between">
                            <b>Место проведения</b>
                            {item.place}
                          </div>
                        </ListGroup.Item>
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>Фамилия</th>
                              <th>Имя</th>
                              <th>E-Mail</th>
                              <th>Телефон</th>
                            </tr>
                          </thead>
                          <tbody>
                          {courses.map((courses) => {
                            return (
                              item._id === courses.sid ?
                            <tr key={courses._id}>
                              <td>{courses.lastName}</td>
                              <td>{courses.firstName}</td>
                              <td>{courses.email}</td>
                              <td>{courses.phone}</td>
                            </tr>
                            :
                              null
                              )
                            })}
                          </tbody>
                        </Table>
                      </ListGroup>
                    </Tab.Pane>
                    )
                  })}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
    );
};

export default observer(CoursesPage);